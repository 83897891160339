import React, {
    useEffect,
    useRef,
    useState
} from 'react'
import '../ForexLive//ForexReportLive.css'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import Whiteloading from '../../assets/whiteloading.png'
import ForexDownIcon from '../../assets/forexDownIcon.png'
import CheckClickForListIcon from '../../assets/checkClickForLIST.png'
import ExportIcon from '../../assets/export.png'
import LeftArrow from '../../assets/leftArrow.png'
import BackWard from '../../assets/backWard.png'
import Forward from '../../assets/forward.png'
import RightArrow from '../../assets/rightArrow.png'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_URL, post, postWithOutToken } from '../Services/Calls';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Automatically registers necessary Chart.js components
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ScheduleComponent, Month, Inject, EventSettingsModel } from '@syncfusion/ej2-react-schedule';
import { extend } from '@syncfusion/ej2-base';
import DynamicCalendar from '../ForexLive/Calendar';


interface IProps {
    openForexReportDialog: any;
    setOpenForexReportDialog: any;
    mobileView: any;
}


function ForexReport({
    openForexReportDialog,
    setOpenForexReportDialog,
    mobileView,
}: IProps) {


    const eventData = [
        {
            Id: 1,
            Subject: 'Profit: $999 | 5 trades',
            StartTime: new Date(2024, 9, 22), // June 2, 2021
            EndTime: new Date(2024, 9, 22),
            IsAllDay: true,
            Profit: '999',
            Trades: '5'
        },
        {
            Id: 2,
            Subject: 'Loss: $128 | 6 trades',
            StartTime: new Date(2021, 5, 5), // June 5, 2021
            EndTime: new Date(2021, 5, 5),
            IsAllDay: true,
            Profit: -128,
            Trades: 6
        },
        {
            Id: 3,
            Subject: 'Profit: $83 | 2 trades',
            StartTime: new Date(2021, 5, 8), // June 8, 2021
            EndTime: new Date(2021, 5, 8),
            IsAllDay: true,
            Profit: 83,
            Trades: 2
        },
        // Add more events here
    ];

    const localData: EventSettingsModel = {
        dataSource: extend([], eventData, {}, true) as Object[]
    };


    const [switchLoader, setSwitchLoader] = useState<boolean>(false);
    const [commissionByApi, setCommissionByApi] = useState(true);
    const [startDate, setStartDate]: any = useState(null);
    const [endDate, setEndDate]: any = useState(null);
    const startDateRef: any = useRef(null);
    const endDateRef: any = useRef(null);
    const [forexDropDownShow, setForexDropDownShow] = useState(false);
    const [limitVal, setLimitVal] = useState("500");
    const [pageVal, setPageVal] = useState("1");
    const [pageArray, setPageArray] = useState([]);
    const [forexData, setForexData]: any = useState([]);
    const [forexGroupData, setForexGroupData]: any = useState([]);
    const [forexItemColumnData, setForexItemColumnData] = useState([
        {
            "item": "audjpy.pro", checked: false
        },
        {
            "item": "audusd.pro", checked: false
        },
        {
            "item": "btcusd.pro", checked: false
        },
        {
            "item": "euraud.pro", checked: false
        },
        {
            "item": "eurcad.pro", checked: false
        },
        {
            "item": "eurchf.pro", checked: false
        },
        {
            "item": "eurgbp.pro", checked: false
        },
        {
            "item": "usdcad.pro", checked: false
        },
        {
            "item": "xaueur.pro", checked: true
        },
        {
            "item": "xauusd.pro", checked: true
        },
        /* {
            "item": "eurchf.pro", checked:false
        },
        {
            "item": "eurgbp.pro", checked:false
        },
        {
            "item": "eurjpy.pro", checked:false
        },
        {
            "item": "eurnzd.pro", checked:false
        },
        {
            "item": "eurusd.pro", checked:false
        },
        {
            "item": "gbpjpy.pro", checked:false
        },
        {
            "item": "gbpusd.pro", checked:false
        },
        {
            "item": "nzdcad.pro", checked:false
        },
        {
            "item": "nzdjpy.pro", checked:false
        },
        {
            "item": "nzdusd.pro", checked:false
        },
        {
            "item": "usdcad.pro", checked:false
        },
        {
            "item": "usdchf.pro", checked:false
        },
        {
            "item": "usdjpy.pro", checked:false
        } */
    ]);



    const [groupColumnData, setGroupColumnData] = useState([
        {
            "item": "All rows and groups", count: 0
        },
        {
            "item": "Only groups 2+", count: 2
        },
        {
            "item": "Only groups 3+", count: 3
        },
        {
            "item": "Only groups 4+", count: 4
        },
        {
            "item": "Only groups 5+", count: 5
        },
        {
            "item": "Only groups 6+", count: 6
        },
        {
            "item": "Only groups 7+", count: 7
        },
        {
            "item": "Only groups 8+", count: 8
        },
        {
            "item": "Only groups 9+", count: 9
        },
        {
            "item": "Only groups 10+", count: 10
        },
        {
            "item": "Only groups 11+", count: 11
        },
        {
            "item": "Only groups 12+", count: 12
        },
    ]);
    const [clickForList, setClickForList]: any = useState({
        "item": "audcad.pro", checked: true
    });
    const [groupsDropVal, setGroupsDropVal]: any = useState({
        "item": "All rows and groups", count: 0
    });
    const [clickForListShow, setClickForListShow] = useState(false);
    const [groupsDropShow, setGroupsDropShow] = useState(false);
    const [reportDropShow, setReportDropShow] = useState(false);
    const [reportStatus, setReportStatus]: any = useState('');
    const [demoAcDropShow, setDemoAcDropShow] = useState(false);
    const demoAccountRef = useRef("");
    const [totalProfit, setTotalProfit] = useState(0);

    const [showAllStatus, setShowAllStatus] = useState(false);


    const navigate = useNavigate();


    const [graphData, setGraphData]: any = useState({});

    const [graphOptions, setGraphGrandOptions]: any = useState({});


    const [columns, setcolumns] = useState([
        { "id": 1, "columnName": "Id", isShow: true },
        { "id": 3, "columnName": "platform", isShow: true },
        { "id": 4, "columnName": "Type", isShow: true },
        { "id": 5, "columnName": "Time", isShow: true },
        { "id": 6, "columnName": "Broker Time", isShow: true },
        /* { "id": 7, "columnName": "Commission", isShow: true },
        { "id": 8, "columnName": "Swap", isShow: true }, */
        { "id": 9, "columnName": "profit", isShow: true },
        { "id": 10, "columnName": "symbol", isShow: true },
        /* { "id": 11, "columnName": "magic", isShow: true }, */
        { "id": 12, "columnName": "orderId", isShow: true },
        { "id": 13, "columnName": "positionId", isShow: true },
        { "id": 14, "columnName": "price", isShow: true },
        { "id": 15, "columnName": "Entry Type", isShow: true },
        { "id": 16, "columnName": "Exchange Rate", isShow: true },
    ]);
    const [groupDataColumns, setForexGroupDatacolumns] = useState([
        { "id": 1, "columnName": "Time In", isShow: true },
        { "id": 2, "columnName": "Position", isShow: true },
        { "id": 3, "columnName": "Price In", isShow: true },
        { "id": 4, "columnName": "S / L", isShow: true },
        { "id": 5, "columnName": "T / P", isShow: true },
        { "id": 6, "columnName": "Price Out", isShow: true },
        { "id": 7, "columnName": "Com", isShow: true },
        { "id": 8, "columnName": "Swap", isShow: true },
        { "id": 9, "columnName": "Symbol", isShow: true },
        { "id": 10, "columnName": "Volume", isShow: true },
        { "id": 11, "columnName": "Type", isShow: true },
        { "id": 12, "columnName": "Time Out", isShow: true },
        { "id": 13, "columnName": "Profit", isShow: true },
        { "id": 14, "columnName": "Nprofit", isShow: true },
        { "id": 15, "columnName": "Tprofit", isShow: true },
        { "id": 15, "columnName": "TNprofit", isShow: true },
        { "id": 15, "columnName": "TVolume", isShow: true },
        { "id": 16, "columnName": "Ttrades", isShow: true }
    ]);
    const [activeTab, setActiveTab] = useState('1D');

    const tabs = ['1D', '1W', '1M', '3M', '6M', '1Y'/* , 'ALL' */];


    const offsetRef = useRef(0)
    const groupOffsetRef = useRef(0)
    const apiCountRef = useRef(1)
    const calApiCountRef = useRef(1)
    const forexDataRef: any = useRef([])
    const dayGroupDataRef: any = useRef([])

    const [yesterdayProfit, setYesterdayProfit] = useState(0);
    const [ThreeDayProfit, setThreeDayProfit] = useState(0);
    const [FourDayProfit, setFourDayProfit] = useState(0);
    const [yesterdayNetProfit, setYesterdayNetProfit] = useState(0);

    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    // const pageSize = 1000; // Number of records per page
    const numbers = Array.from({ length: totalPages }, (_, index) => index + 1);



    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 1000;
    // const totalPages = Math.ceil(forexDataRef.current.length / itemsPerPage);

    // Get the data for the current page


    // Handle changing pages
    const [fromData, setfromData] = useState(0);
    const [toData, settoData] = useState(1000);
    const onPageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
        if (newPage == 1) {
            setfromData(0)
            settoData(1000)
        }
        else {
            setfromData((newPage - 1) * 1000)
            settoData(newPage * 1000)
        }

    };


    const [utcPlusThreeTime, setUtcPlusThreeTime] = useState('');
    const [utcMinusSevenTime, setUtcMinusSevenTime] = useState('');

    useEffect(() => {

        const utcTime = new Date().toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
        console.log('utcTime', utcTime)

        const updateTime = () => {
            const currentTime = new Date(new Date().getTime() - 7 * 60 * 60 * 1000)
                .toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false })
                .replace(',', '');

            // console.log('utcTime-7', currentTime)

            setUtcMinusSevenTime(currentTime);

            const serverTime = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '');

            // console.log('utcTime+3', serverTime)

            setUtcPlusThreeTime(serverTime);

        };

        updateTime(); // Initial call
        const intervalId = setInterval(updateTime, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const serverTime11 = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '');
            console.log('serverTime11', new Date(serverTime11).toISOString())
            let serverStartTime1 = new Date(serverTime11).toISOString()
            let serverTime1: any = new Date(serverTime11).toISOString()
            // let serverStartTime:any = new Date()
            // let serverTime:any = new Date()
            let serverStartTime: any = new Date(serverStartTime1)
            let serverTime: any = new Date(serverTime1)
            serverStartTime.setHours(0, 0, 0, 0)
            serverTime.setHours(23, 59, 0, 0)
            setStartDate(new Date(serverStartTime));
            startDateRef.current = new Date(serverStartTime)
            setEndDate(new Date(serverTime));
            endDateRef.current = new Date(serverTime)
            const d1 = new Date(serverStartTime);
            const ed1 = new Date(serverTime);
            demoAccountRef.current = "ed6163b9-3c1c-48ef-bd18-ac0c4fec8504"
            if (mobileView) {
                setReportStatus('Profit - Calendar')
                await getYesterdayProfit(new Date(d1.setDate(d1.getDate() - 1)).toISOString(), new Date(ed1.setDate(ed1.getDate() - 1)).toISOString())
            }
            else {
                setReportStatus('Profit - Data')
            }
            await getForexData(new Date(d1.toISOString()), new Date(ed1.toISOString()))

            // getThreeDayProfit(new Date(d1.setDate(d1.getDate() - 2)).toISOString(), new Date(ed1.setDate(ed1.getDate() - 2)).toISOString())
            // getFourDayProfit(new Date(d1.setDate(d1.getDate() - 3)).toISOString(), new Date(ed1.setDate(ed1.getDate() - 3)).toISOString())
            console.log("moment",);
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     // calApiCountRef.current = 1
    //     // calculateCalenderData()
    //     // setReportStatus('Profit - Calendar')

    // }, [])


    async function getDemoAccountData() {
        await getForexData(startDateRef.current, endDateRef.current);
    }



    const getYesterdayProfit = async (
        sDate,
        eDate
    ) => {
        console.log("config123", sDate)
        console.log("config123", eDate)
        setSwitchLoader(true);

        // let d = new Date(sDate.getTime() - (9 * 60 * 60 * 1000)).toISOString()
        // let ed = new Date(eDate.getTime() - (9 * 60 * 60 * 1000)).toISOString()
        let d = new Date(new Date(sDate).setHours(new Date(sDate).getHours() - 9)).toISOString()
        let ed = new Date(new Date(eDate).setHours(new Date(eDate).getHours() - 9)).toISOString()
        console.log("config123", d)
        console.log("config123", ed)

        let config = {
            fromDate: d,
            toDate: ed,
            limit: 1000,
            offset: offsetRef.current,
            accountNo: demoAccountRef.current
        }
        console.log("config123", config)
        try {
            const apiData = await post(BASE_URL + '/getforexdatanewlive', config)
            console.log('apiData', apiData)
            if (/* apiData.data.status == 'success' &&  */apiData.data.data) {
                let apiData1 = JSON.parse(apiData.data.data);
                if (apiData1.length > 0) {
                    console.log("apiData45", apiData1)

                    for (let i = 0; i < apiData1.length; i++) {

                        apiData1[i].commission = parseFloat(apiData1[i].commission);
                        apiData1[i].netProfit = parseFloat(apiData1[i].netProfit);
                        apiData1[i].price = parseFloat(apiData1[i].price);
                        apiData1[i].profit = parseFloat(apiData1[i].profit);
                        apiData1[i].swap = parseFloat(apiData1[i].swap);
                        apiData1[i].takeProfit = parseFloat(apiData1[i].takeProfit);
                        apiData1[i].volume = parseFloat(apiData1[i].volume);
                    }

                    let tempData = apiData1;
                    for (let i = 0; i < apiData1.length; i++) {
                        let commission: any = 0
                        let timeIn: any = ""
                        let priceIn: any = 0
                        let type: any = "DEAL_TYPE_BUY"
                        for (let j = 0; j < tempData.length; j++) {
                            if (tempData[j].positionId == apiData1[i].positionId && tempData[j].commission != 0) {
                                commission = tempData[j].commission
                                timeIn = tempData[j].brokerTime
                                priceIn = tempData[j].price
                                type = tempData[j].type
                            }
                        }
                        apiData1[i].time = timeIn;
                        apiData1[i].commission = commission;
                        apiData1[i].priceIn = priceIn;
                        apiData1[i].type = type;
                        apiData1[i].netProfit = commission + apiData1[i].swap + apiData1[i].profit;
                    }

                    if (commissionByApi) {
                        for (let i = 0; i < apiData1.length; i++) {
                            if (apiData1[i].type === "DEAL_TYPE_SELL" || apiData1[i].type === "DEAL_TYPE_BUY") {
                                if (apiData1[i].commission == 0) {
                                    let resbyPositionID: any = await getCommissionByPositionId(apiData1[i].positionId)
                                    console.log("resbyPositionID", resbyPositionID)
                                    if (resbyPositionID && resbyPositionID.commission) {
                                        console.log("resbyPositionID1", resbyPositionID.commission)
                                        apiData1[i].commission = resbyPositionID.commission;
                                        apiData1[i].time = resbyPositionID.timeIn;
                                        apiData1[i].priceIn = resbyPositionID.priceIn;
                                        apiData1[i].type = resbyPositionID.type;
                                    }
                                }
                            }
                        }
                    }

                    console.log("apiData456786", apiData1)
                    let data = apiData1
                    let profit = 0.0;
                    for (let i = 0; i < data.length; i++) {
                        if (checkSymbol(data[i])) {
                            profit = profit + parseFloat(data[i].profit);
                        }
                    }
                    console.log('profit75', profit)
                    setYesterdayProfit(profit)

                    let netProfit = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].profit != 0) {
                            let commission: any = parseFloat(data[i].commission) || 0;
                            let swap: any = parseFloat(data[i].swap) || 0;
                            let profit: any = parseFloat(data[i].profit) || 0;
                            let itemNetProfit = parseFloat(commission) + parseFloat(swap) + parseFloat(profit);
                            data[i].netProfit = itemNetProfit;
                            if (checkSymbol(data[i])) {
                                netProfit += itemNetProfit;
                            }
                        }
                    }
                    console.log('profit75', netProfit)
                    setYesterdayNetProfit(netProfit)
                    setSwitchLoader(false);
                    // calculateYesterdayProfit(apiData1)
                    // calculateYesterdayNetProfit(apiData1)
                }
                else {
                    setSwitchLoader(false);
                }
            }
            else {
                setSwitchLoader(false);
            }


        } catch (ex) {
            setSwitchLoader(false);
            console.log('response9211', ex)
        }
    }


    const getForexData = async (
        sDate,
        eDate
    ) => {
        // let d1 = new Date(new Date(sDate).getTime() + (2 * 60 * 60 * 1000) + (30 * 60 * 1000))
        // let ed1 = new Date(new Date(eDate).getTime() + (2 * 60 * 60 * 1000) + (30 * 60 * 1000))
        // let d = new Date(d1).toISOString().replace('T', ' ').slice(0, 19);
        // let ed = new Date(ed1).toISOString().replace('T', ' ').slice(0, 19);



        await manualSync(sDate,
            eDate)
    }

    const getForexData1 = async (
        sDate,
        eDate
    ) => {


        setSwitchLoader(true);

        let s1Date = new Date(new Date(sDate).setDate(new Date(sDate).getDate() - 1)).toISOString()
        let e1Date = new Date(new Date(eDate).setDate(new Date(eDate).getDate() + 1)).toISOString()
        console.log('s1Date', new Date(new Date(sDate).setDate(new Date(sDate).getDate() - 1)).toISOString())
        console.log('e1Date', new Date(new Date(eDate).setDate(new Date(eDate).getDate() + 1)).toISOString())

        let config = {
            fromDate: s1Date,
            toDate: e1Date,
            limit: 1000,
            offset: offsetRef.current,
            accountNo: demoAccountRef.current
        }
        console.log("config456", config)
        try {
            const apiData = await post(BASE_URL + '/getforexdatanewlive', config)
            console.log('apiData', apiData)
            if (/* apiData.data.status == 'success' &&  */apiData.data.data) {
                let apiData1 = JSON.parse(apiData.data.data);
                console.log("apiCount1122", apiCountRef.current)
                if (apiCountRef.current == 1 && apiData1.length == 0) {
                    setTotalProfit(0)
                    setPageArray([]);
                    setSwitchLoader(false);
                    setForexData([])
                    profitGraphFun([])
                    groupFun([])
                    profitCalFun([])
                    // setautoCallRemainingTime(5);
                    // timer1.current = 60 * 5
                    offsetRef.current = 0
                    forexDataRef.current = []
                }
                else if (apiData1.length > 0) {
                    apiCountRef.current++
                    console.log("apiCount11223", apiCountRef.current)
                    console.log("apiData45", apiData1)
                    for (let i = 0; i < apiData1.length; i++) {

                        apiData1[i].commission = parseFloat(apiData1[i].commission);
                        apiData1[i].netProfit = parseFloat(apiData1[i].netProfit);
                        apiData1[i].price = parseFloat(apiData1[i].price);
                        apiData1[i].profit = parseFloat(apiData1[i].profit);
                        apiData1[i].swap = parseFloat(apiData1[i].swap);
                        apiData1[i].takeProfit = parseFloat(apiData1[i].takeProfit);
                        apiData1[i].volume = parseFloat(apiData1[i].volume);
                    }
                    let tempData = apiData1;
                    for (let i = 0; i < apiData1.length; i++) {
                        let commission: any = 0
                        let timeIn: any = ""
                        let priceIn: any = 0
                        let type: any = "DEAL_TYPE_BUY"
                        for (let j = 0; j < tempData.length; j++) {
                            if (tempData[j].positionId == apiData1[i].positionId && tempData[j].commission != 0) {
                                commission = tempData[j].commission
                                timeIn = tempData[j].brokerTime
                                priceIn = tempData[j].price
                                type = tempData[j].type
                            }
                        }
                        // if(commission==0)
                        // {
                        //     let resbyPositionID:any=await getCommissionByPositionId(apiData1[i].positionId)
                        //     commission=resbyPositionID.commission;
                        // }
                        apiData1[i].time = timeIn;
                        apiData1[i].commission = commission;
                        apiData1[i].priceIn = priceIn;
                        apiData1[i].type = type;

                        apiData1[i].netProfit = commission + apiData1[i].swap + apiData1[i].profit;
                        // apiData1[i].netProfit = getCommission(apiData1[i].positionId) + apiData1[i].swap + apiData1[i].profit;

                    }
                    console.log('apiDataapiData', apiData1[0])
                    console.log('sDate25', sDate)
                    console.log('eDate25', eDate);
                    console.log('sDate251', startDateRef.current)
                    console.log('eDate251', endDateRef.current);


                    // for(let i=0;i<apiData1.length;i++){
                    //     if(apiData1[i].commission == 0){

                    //     }
                    // }




                    const start = new Date(startDateRef.current);
                    const end = new Date(endDateRef.current);

                    const result = apiData1.filter(item => {
                        const brokerTime = new Date(item.brokerTime);
                        return brokerTime >= start && brokerTime <= end;
                    });


                    apiData1 = result
                    console.log("positionid45", apiData1)
                    if (commissionByApi) {
                        for (let i = 0; i < apiData1.length; i++) {
                            if (apiData1[i].type === "DEAL_TYPE_SELL" || apiData1[i].type === "DEAL_TYPE_BUY") {
                                if (apiData1[i].commission == 0) {
                                    console.log("positionid451", apiData1[i])
                                    console.log("positionid451", apiData1[i].positionId)
                                    let resbyPositionID: any = await getCommissionByPositionId(apiData1[i].positionId)
                                    console.log("resbyPositionID", resbyPositionID)
                                    if (resbyPositionID && resbyPositionID.commission) {
                                        console.log("resbyPositionID1", resbyPositionID.commission)
                                        apiData1[i].commission = resbyPositionID.commission;
                                        apiData1[i].time = resbyPositionID.timeIn;
                                        apiData1[i].priceIn = resbyPositionID.priceIn;
                                        apiData1[i].type = resbyPositionID.type;
                                    }
                                }
                            }
                        }
                    }

                    /*  for(let i=0;i<apiData1.length;i++){
 
                     } */
                    console.log("apiData456", apiData1)

                    // setForexData(apiData1);
                    // groupFun(apiData1)
                    // profitGraphFun(apiData1)
                    //setData(apiData1)                
                    setSwitchLoader(false);
                    // profitCalFun(apiData1)
                    // setautoCallRemainingTime(5);
                    // timer1.current = 60 * 5
                    offsetRef.current = offsetRef.current + 1000
                    forexDataRef.current = [...forexDataRef.current, ...apiData1];

                    // let tempData1 = forexDataRef.current.filter(item => item.profit !== 0)
                    // forexDataRef.current = tempData1

                    forexDataRef.current = forexDataRef.current.sort((a, b) => new Date(a.brokerTime).getTime() - new Date(b.brokerTime).getTime());
                    getForexData1(sDate, eDate)
                }
                else {
                    // if (forexDataRef && forexDataRef.current && forexDataRef.current.length > 0) {
                    console.log('forexDataRef.current45', forexDataRef.current)
                    setForexData(forexDataRef.current);
                    groupFun(forexDataRef.current)
                    profitGraphFun(forexDataRef.current)
                    profitCalFun(forexDataRef.current)
                    setSwitchLoader(false);
                    offsetRef.current = 0;
                    setTotalPages(Math.ceil(forexDataRef.current.length / itemsPerPage))
                    setTotalItems(forexDataRef.current.length)
                    forexDataRef.current = []
                    // }


                    // setTotalProfit(0)
                    // setPageArray([]);
                    // setSwitchLoader(false);
                    // setForexData([])
                    // profitGraphFun([])
                    // groupFun([])
                    // profitCalFun([])
                    // setautoCallRemainingTime(5);
                    // timer1.current = 60 * 5
                }
            }
            else {
                setTotalProfit(0)
                setPageArray([]);
                setSwitchLoader(false);
                setForexData([])
                profitGraphFun([])
                groupFun([])
                profitCalFun([])
                // setautoCallRemainingTime(5);
                // timer1.current = 60 * 5
                offsetRef.current = 0
                forexDataRef.current = []
            }


        } catch (ex) {
            offsetRef.current = 0
            forexDataRef.current = []
            // setautoCallRemainingTime(5)
            // timer1.current = 60 * 5
            setSwitchLoader(false);
            console.log('response9211', ex)
        }
    }



    const getCommissionByPositionId = async (position) => {


        let config = {
            positionid: position,
            accountNo: demoAccountRef.current
        }
        console.log("config456", config)
        try {
            const apiData = await post(BASE_URL + '/getforexdatanewbyposition', config)
            console.log('apiData', apiData)
            if (/* apiData.data.status == 'success' &&  */apiData.data.data) {
                let apiData1 = JSON.parse(apiData.data.data);
                if (apiData1.length > 0) {
                    //  console.log("apiData454545", apiData1)
                    let tempData = apiData1;

                    let a = { commission: 0, profit: 0, timeIn: "", priceIn: 0, type: "" }


                    for (let i = 0; i < apiData1.length; i++) {
                        if (apiData1[i].profit != 0) a.profit = apiData1[i].profit;
                        if (apiData1[i].commission != 0) {
                            a.commission = apiData1[i].commission;
                            a.timeIn = apiData1[i].brokerTime;
                            a.priceIn = apiData1[i].price;
                            a.type = apiData1[i].type;
                        }
                        return a
                    }
                }
                else {
                }
            }
            else {
            }


        } catch (ex) {
            console.log('response9211', ex)
        }
    }

    function checkDisplay(index) {
        let status = false;
        for (let i = 0; i < columns.length; i++) {
            if (i == index)
                status = columns[i].isShow;
        }
        return status;
    }

    function checkSymbol(item) {
        let status = false;
        if (item.symbol && (item.type == "DEAL_TYPE_SELL" || item.type == "DEAL_TYPE_BUY")) {
            for (let i = 0; i < forexItemColumnData.length; i++) {
                let a = item.symbol.replace('.pro', '').replace('.PRO', '').replace('.Pro', '');
                let b = forexItemColumnData[i].item.replace('.pro', '').replace('.PRO', '').replace('.Pro', '')
                if (item && item.symbol && forexItemColumnData[i] && forexItemColumnData[i].item) {
                    if (a.toLowerCase() == b.toLowerCase())
                        status = forexItemColumnData[i].checked;
                }
            }
        }
        return status;
    }

    function calculateProfit() {
        let profit = 0.0;
        for (let i = 0; i < forexData.length; i++) {

            if (checkSymbol(forexData[i])) {
                profit = profit + parseFloat(forexData[i].profit);
            }
        }
        return profit;
    }

    function calculateNetProfit() {
        let netProfit = 0;

        for (let i = 0; i < forexData.length; i++) {
            if (forexData[i].profit != 0) {
                // console.log('test');
                let commission: any = getCommission(forexData[i].positionId) || 0;
                // console.log('test1', commission);
                let swap: any = parseFloat(forexData[i].swap) || 0;
                let profit: any = parseFloat(forexData[i].profit) || 0;
                // console.log('test3', profit);

                let itemNetProfit = parseFloat(commission) + parseFloat(swap) + parseFloat(profit);
                forexData[i].netProfit = itemNetProfit;
                // console.log('test4', itemNetProfit);
                if (checkSymbol(forexData[i])) {
                    netProfit += itemNetProfit;
                }
            }
        }

        // console.log('asdfg', netProfit)

        return netProfit;
    }

    function calculateYesterdayProfit(data) {
        let profit = 0.0;
        for (let i = 0; i < data.length; i++) {

            if (checkSymbol(data[i])) {
                profit = profit + parseFloat(data[i].profit);
            }
        }
        console.log('profit78', profit)
        setYesterdayProfit(profit)
    }

    function calculateThreeDaysProfit(data) {
        let profit = 0.0;
        for (let i = 0; i < data.length; i++) {

            if (checkSymbol(data[i])) {
                profit = profit + parseFloat(data[i].profit);
            }
        }
        setThreeDayProfit(profit)
    }
    function calculateFourDaysProfit(data) {
        let profit = 0.0;
        for (let i = 0; i < data.length; i++) {

            if (checkSymbol(data[i])) {
                profit = profit + parseFloat(data[i].profit);
            }
        }
        setFourDayProfit(profit)
    }

    function calculateYesterdayNetProfit(data) {
        let netProfit = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].profit != 0) {
                // console.log('test');
                let commission: any = parseFloat(data[i].commission) || 0;
                // console.log('test1', commission);
                let swap: any = parseFloat(data[i].swap) || 0;
                let profit: any = parseFloat(data[i].profit) || 0;
                // console.log('test3', profit);

                let itemNetProfit = parseFloat(commission) + parseFloat(swap) + parseFloat(profit);
                data[i].netProfit = itemNetProfit;
                // console.log('test4', itemNetProfit);
                if (checkSymbol(data[i])) {
                    netProfit += itemNetProfit;
                }
            }
        }
        console.log('profit78', netProfit)
        setYesterdayNetProfit(netProfit)
    }


    function collapseRow(indexes) {

        console.log('indexes', indexes)
        let status = false;
        let tempData: any = [...forexGroupData];

        tempData[indexes][0].isShow = tempData[indexes][0].isShow ? false : true;
        console.log("tempData", tempData)
        setForexGroupData(tempData);
    }

    function setData(data) {
        let newData: any = [];
        let preBorder = false;
        let tradeCount = 1;
        let size = 0;

        let profit = 0;
        let collapseIndex = "";
        let profitMinusTradeCount = 0;
        for (let i = 0; i < data.length; i++) {

            //newData.push(data[i]);
            if (i != 0) {
                let id = data[i].Fed.replace('TheFED-', '').replace('[tp]', '').replace('TheFed-', '');
                let idArray = id.split('-');
                let previousId = data[i - 1].Fed.replace('TheFED-', '').replace('[tp]', '').replace('TheFed-', '');
                let previousIdArray = previousId.split('-');
                if (idArray[0] > 0 && previousIdArray[0] == 0) {
                    data[i - 1].isBorderTop = true;
                    preBorder = true;
                    size = size + parseFloat(data[i - 1].Size);
                    profit = profit + parseFloat(data[i - 1].Profit);
                    if (data[i - 1].Profit < 0) {
                        profitMinusTradeCount = profitMinusTradeCount + 1;
                    }

                }
                if (data.length - 1 != i && preBorder) {

                    data[i].isBorderLeftRight = true;
                    tradeCount = tradeCount + 1;
                    size = size + parseFloat(data[i].Size);
                    profit = profit + parseFloat(data[i].Profit);
                    if (data[i].Profit < 0) {
                        profitMinusTradeCount = profitMinusTradeCount + 1;
                    }

                    let nextId = data[i + 1].Fed.replace('TheFED-', '').replace('[tp]', '').replace('TheFed-', '');
                    let nextIdArray = nextId.split('-');
                    if (idArray[0] > 0 && nextIdArray[0] == 0) {
                        if (collapseIndex != "") {
                            collapseIndex = collapseIndex + "," + i;
                        }
                        else {
                            collapseIndex = i.toString();
                        }
                        data[i].isBorderBottom = true;
                        /* data[i].tradeCount=tradeCount;
                        data[i].groupSize=size.toFixed(2);
                        data[i].groupProfit=profit.toFixed(2);
                        data[i].plus="collapse"; */
                        data[i].collapseIndex = collapseIndex;
                        data[i].collapseData = true;
                        data[i].hide = true;

                        data[i].ShowTradeCount = tradeCount;
                        let collapseIndexArr: any = collapseIndex.split(',')
                        for (let k = 0; k < collapseIndexArr.length; k++) {
                            console.log("45", collapseIndexArr[k])
                            if (collapseIndexArr[k]) {
                                data[collapseIndexArr[k]].ShowTradeCount = tradeCount;
                            }
                            /* data[collapseIndexArr[k]].ShowTradeCount=tradeCount; */
                        }
                        if (collapseIndexArr[0]) {
                            data[collapseIndexArr[0] - 1].ShowTradeCount = tradeCount;
                            data[collapseIndexArr[0] - 1].tradeCount = tradeCount;
                            data[collapseIndexArr[0] - 1].profitMinusTradeCount = profitMinusTradeCount;
                            data[collapseIndexArr[0] - 1].groupSize = size.toFixed(2);
                            data[collapseIndexArr[0] - 1].groupProfit = profit.toFixed(2);
                            data[collapseIndexArr[0] - 1].plus = "collapse";
                            data[collapseIndexArr[0] - 1].collapseIndex = collapseIndex;
                            data[collapseIndexArr[0] - 1].collapseData = true;
                        }

                        preBorder = false;
                        console.log("data45", profit.toFixed(2));
                        tradeCount = 1
                        profitMinusTradeCount = 0
                        size = 0
                        profit = 0
                        collapseIndex = "";
                    }
                    else {
                        if (collapseIndex != "") {
                            collapseIndex = collapseIndex + "," + i;
                        }
                        else {
                            collapseIndex = i.toString();
                        }
                        data[i].hide = true;
                    }
                }
                else if (data.length - 1 == i && preBorder) {
                    data[i].isBorderLeftRight = true;
                    if (collapseIndex != "") {
                        collapseIndex = collapseIndex + "," + i;
                    }
                    else {
                        collapseIndex = i.toString();
                    }
                    tradeCount = tradeCount + 1;
                    size = size + parseFloat(data[i].Size);
                    profit = profit + parseFloat(data[i].Profit);
                    data[i].isBorderBottom = true;
                    if (data[i].Profit < 0) {
                        profitMinusTradeCount = profitMinusTradeCount + 1;
                    }
                    /* data[i].tradeCount=tradeCount;
                    data[i].groupSize=size.toFixed(2);
                    data[i].groupProfit=profit.toFixed(2);
                    data[i].plus="collapse"; */
                    data[i].collapseIndex = collapseIndex;
                    data[i].collapseData = true;


                    data[i].ShowTradeCount = tradeCount;
                    let collapseIndexArr: any = collapseIndex.split(',')
                    for (let k = 0; k < collapseIndexArr.length; k++) {
                        console.log("45", collapseIndexArr[k])
                        if (collapseIndexArr[k]) {
                            data[collapseIndexArr[k]].ShowTradeCount = tradeCount;
                        }
                        /* data[collapseIndexArr[k]].ShowTradeCount=tradeCount; */
                    }
                    if (collapseIndexArr[0]) {
                        data[collapseIndexArr[0] - 1].ShowTradeCount = tradeCount;
                        data[collapseIndexArr[0] - 1].tradeCount = tradeCount;
                        data[collapseIndexArr[0] - 1].profitMinusTradeCount = profitMinusTradeCount;
                        data[collapseIndexArr[0] - 1].groupSize = size.toFixed(2);
                        data[collapseIndexArr[0] - 1].groupProfit = profit.toFixed(2);
                        data[collapseIndexArr[0] - 1].plus = "collapse";
                        data[collapseIndexArr[0] - 1].collapseIndex = collapseIndex;
                        data[collapseIndexArr[0] - 1].collapseData = true;
                    }

                    preBorder = false;
                    console.log("data45", profit.toFixed(2))
                    tradeCount = 1
                    size = 0
                    profit = 0
                    collapseIndex = "";

                }

            }
        }
        console.log("data45", data)
        setForexData(data);
    }


    const getforexitemcolumndata = async () => {
        let config = {}
        try {
            const apiData = await post(BASE_URL + '/getforexitemcolumndata', config)
            console.log('apiData1122', apiData)
            if (apiData.data.status == 'success' && apiData.data.data && apiData.data.data.length > 0) {
                let tempApiData: any = apiData.data.data
                for (let i = 0; i < tempApiData.length; i++) {
                    if (i == 0) {
                        tempApiData[i].checked = true
                    }
                    else {
                        tempApiData[i].checked = false
                    }
                }
                console.log('tempApiData', tempApiData)
                setForexItemColumnData(tempApiData)
            }
            else {
            }
        } catch (ex) {

        }
    }

    function checkUncheck(item) {
        let tempData: any = [...forexItemColumnData]
        for (let i = 0; i < tempData.length; i++) {
            if (tempData[i].item == item.item) {

                console.log("test45", item);
                if (tempData[i].checked) {
                    tempData[i].checked = false
                }
                else {
                    tempData[i].checked = true
                }
            }
            /* else{
                tempData[i].checked = false
            } */
        }
        console.log('tempData', tempData)
        setForexItemColumnData([...tempData])
    }

    function checkUncheckAll(string) {
        let tempData: any = [...forexItemColumnData]
        for (let i = 0; i < tempData.length; i++) {
            if (string == 'uncheckAll') {
                tempData[i].checked = false
            }
            else {
                tempData[i].checked = true
            }
        }
        console.log('tempData', tempData)

        setForexItemColumnData(tempData)
    }

    function showAllFun() {
        setShowAllStatus(!showAllStatus)
    }

    // const onPageChange = (page) => {
    //     setPageVal(page)
    //     //getForexData(startDate, endDate, limitVal, page, forexItemColumnData);
    // };


    const exportToExcel = async () => {
        // Create a new workbook

        let tempForexData = [...forexData]

        let tempArray: any = []

        for (let i = 0; i < tempForexData.length; i++) {
            if (tempForexData[i].profit != 0) {
                let json: any =
                {
                    "Time": tempForexData[i].time,
                    "Position": tempForexData[i].positionId,
                    "Symbol": tempForexData[i].symbol,
                    "Type": tempForexData[i].type,
                    "Volume": tempForexData[i].volume,
                    "Price": tempForexData[i].takeProfit,
                    "S / L": null,
                    "T / P": tempForexData[i].takeProfit,
                    "Time1": tempForexData[i].brokerTime,
                    "Price1": tempForexData[i].price,
                    "Commission": getCommission(tempForexData[i].positionId),
                    "Swap": tempForexData[i].swap,
                    "Profit": tempForexData[i].profit,
                }
                //   console.log('tempArray',json)
                tempArray.push(json)
            }
        }
        console.log('tempArray', tempArray)

        const workbook = XLSX.utils.book_new();

        // Convert JSON data to a worksheet
        const worksheet = await XLSX.utils.json_to_sheet(tempArray);

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "ForexReport.xlsx");
    };

    const [calenderData, setCalenderData]: any = useState([]);

    function getWeeksInMonth(year, month) {
        const weeks: any = [];
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfMonth = new Date(year, month + 1, 0); // Last day of the month

        let currentDay: any = new Date(firstDayOfMonth);

        // Handle the first week, which might not start on Sunday
        const firstWeek: any = [];
        for (let i = 0; i < 7; i++) {
            if (i < currentDay.getDay()) {
                firstWeek.push(null); // Empty slots before the first day of the month
            } else {
                firstWeek.push(new Date(currentDay));
                currentDay.setDate(currentDay.getDate() + 1);
            }
        }
        weeks.push(firstWeek);

        // Handle the rest of the weeks
        while (currentDay <= lastDayOfMonth) {
            const week: any = [];
            for (let i = 0; i < 7; i++) {
                if (currentDay.getMonth() === month) {
                    week.push(new Date(currentDay)); // Add the day to the week
                } else {
                    week.push(null); // Fill remaining slots with null
                }
                currentDay.setDate(currentDay.getDate() + 1); // Move to the next day
            }
            weeks.push(week);
        }




        return weeks;
    }


    async function calculateCalenderData(now = new Date()) {


        let weeks = getWeeksInMonth(now.getFullYear(), now.getMonth());
        // let now = new Date();
        // Get the first day of the current month (YYYY-MM-01)
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

        // Get the last day of the current month
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        // Format the dates to YYYY-MM-DD
        // const firstDate = firstDayOfMonth.toISOString().split('T')[0];
        // const lastDate = lastDayOfMonth.toISOString().split('T')[0];

        let s1Date = new Date(new Date(utcPlusThreeTime).setDate(new Date(utcPlusThreeTime).getDate() - 10)).toISOString()
        let e1Date = new Date(new Date(utcPlusThreeTime).setDate(new Date(utcPlusThreeTime).getDate() + 1)).toISOString()


        const firstDate = s1Date.split('T')[0];
        const lastDate = e1Date.split('T')[0];

        // console.log('s1Date1122',s1Date)
        // console.log('s1Date1122',e1Date)
        // console.log('s1Date1122',new Date(s1Date).toISOString())
        // console.log('s1Date1122',new Date(e1Date).toISOString())

        let config = {
            fromDate: firstDate,
            toDate: lastDate,
            limit: 1000,
            offset: groupOffsetRef.current,
            accountNo: demoAccountRef.current
        }

        console.log('s1Date1122', config)
        try {
            setSwitchLoader(true);
            const apiData = await post(BASE_URL + '/getforexdatanewlive', config)
            console.log('apiData', apiData)
            if (/* apiData.data.status == 'success' &&  */apiData.data.data) {
                let apiData1 = JSON.parse(apiData.data.data);
                console.log("apiCount1122", calApiCountRef.current)
                if (calApiCountRef.current == 1 && apiData1.length == 0) {
                    setSwitchLoader(false);
                    groupOffsetRef.current = 0
                    dayGroupDataRef.current = []
                }
                else if (apiData1.length > 0) {
                    calApiCountRef.current++
                    groupOffsetRef.current = groupOffsetRef.current + 1000
                    dayGroupDataRef.current = [...dayGroupDataRef.current, ...apiData1];
                    console.log('dayGroupDataRef.current', dayGroupDataRef.current)
                    setSwitchLoader(false);
                    calculateCalenderData()
                    // calculateCalenderData1(dailyProfitsArray);
                    // calculateWeekData(dailyProfitsArray,weeks)
                }
                else {
                    if (dayGroupDataRef && dayGroupDataRef.current && dayGroupDataRef.current.length > 0) {
                        console.log('dayGroupDataRef.current4545', dayGroupDataRef.current)

                        const dailyProfits = {};

                        for (let i = 0; i < dayGroupDataRef.current.length; i++) {
                            const date = dayGroupDataRef.current[i].brokerTime.split(' ')[0];
                            const profit = dayGroupDataRef.current[i].profit;

                            // Check if this date already exists in dailyProfits
                            if (dailyProfits[date]) {
                                dailyProfits[date].totalProfit += profit;
                                dailyProfits[date].trades.push(profit); // Add trade profit to trades array
                            } else {
                                // Initialize dailyProfits[date] as an object with totalProfit and trades properties
                                dailyProfits[date] = {
                                    totalProfit: profit,
                                    trades: [profit] // Initialize trades array with the first trade's profit
                                };
                            }
                        }

                        const dailyProfitsArray: any = [];
                        for (let date in dailyProfits) {
                            dailyProfitsArray.push({
                                date: date,
                                totalProfit: dailyProfits[date].totalProfit,
                                tTrades: dailyProfits[date].trades.length // Dynamic count of trades
                            });
                        }

                        console.log("dailyProfitsArray123", dailyProfitsArray);

                        calculateCalenderData1(dailyProfitsArray);
                        calculateWeekData(dailyProfitsArray, weeks)
                        // await calculateCalenderData1(dayGroupDataRef.current);
                        setSwitchLoader(false);
                        groupOffsetRef.current = 0;
                        dayGroupDataRef.current = []
                    }
                }
            }
            else {
                setSwitchLoader(false);
                groupOffsetRef.current = 0
                dayGroupDataRef.current = []
            }


        } catch (ex) {
            setSwitchLoader(false);
            dayGroupDataRef.current = []
            console.log('response345', ex)
        }
    }


    const [weekProfitData, setweekProfitData]: any = useState([]);


    function calculateWeekData(dayGroupData, weeks) {
        let weekdata: any = [];
        /*  console.log('1111 dayGroupData 1', dayGroupData)
         console.log('1111 dayGroupData 2', JSON.stringify(weeks)) */
        for (let i = 0; i < weeks.length; i++) {
            // weekdata.push({'week':i+1,})

            let weekDay = 0;
            let weekprofit = 0;
            for (let j = 0; j < weeks[i].length; j++) {
                if (weeks[i][j] != null) {
                    for (let k = 0; k < dayGroupData.length; k++) {




                        let weekdate = weeks[i][j].getFullYear() + '-' + String(weeks[i][j].getMonth() + 1).padStart(2, '0') + '-' + String(weeks[i][j].getDate()).padStart(2, '0');
                        let daydate = dayGroupData[k].date;

                        /* console.log('weekdate',weekdate)
                        console.log('daydate',daydate) */

                        if (weekdate == daydate) {
                            weekprofit = weekprofit + dayGroupData[k].totalProfit
                        }

                    }
                    weekDay++;
                }

            }
            weekdata.push({ 'week': i + 1, 'totalDay': weekDay, "weekprofit": weekprofit.toFixed(0) })

        }


        setweekProfitData(weekdata)
        console.log('weekdata weekdata', weekdata)

    }


    function calculateCalenderData1(groupeddata) {

        console.log("dailyProfitsArray123", groupeddata);
        let calData: any = [];
        for (let i = 0; i < groupeddata.length; i++) {
            let item = {
                Id: i,
                Subject: 'Profit: ' + groupeddata[i].totalProfit.toFixed(0) + ' | ' + groupeddata[i].tTrades + ' trades',
                StartTime: groupeddata[i].date,
                EndTime: groupeddata[i].date,
                IsAllDay: true,
                Profit: groupeddata[i].totalProfit.toFixed(0),
                Trades: groupeddata[i].tTrades
            }
            calData.push(item);
        }
        console.log('calenderdata11', calData)
        const localData: EventSettingsModel = {
            dataSource: extend([], calData, {}, true) as Object[]
        };

        setCalenderData(localData)
        console.log('calenderdata11', calData)
        console.log('localData45', localData)
    }


    function groupFun(data) {
        let tempForexData = data;

        // console.log('tempForexData', tempForexData)
        let sorted: any = []

        sorted = tempForexData.sort((a, b) => new Date(a.brokerTime).getTime() - new Date(b.brokerTime).getTime());
        // console.log("sorted", sorted);

        const grouped: any = [];

        for (let i = 0; i < sorted.length; i++) {
            const item = sorted[i];
            // const timeKey = item.brokerTime.substring(0, 16);
            const timeKey = item.brokerTime.substring(0, 19);

            console.log("timeKey", timeKey)

            let existingGroup: any = null;

            if (sorted[i].profit != 0) {
                for (let j = 0; j < grouped.length; j++) {
                    // if (grouped[j].some(entry => entry.brokerTime.startsWith(timeKey))) {
                    //     // console.log('grouped[j].profit', grouped[j].profit)
                    //     existingGroup = grouped[j];
                    //     break;
                    // }

                    if (grouped[j].some(entry => {
                        const entryTime = new Date(entry.brokerTime);
                        const currentItemTime = new Date(item.brokerTime);

                        return (
                            currentItemTime >= entryTime &&
                            currentItemTime < new Date(entryTime.getTime() + 60000)
                        );
                    })) {
                        existingGroup = grouped[j];
                        break;
                    }

                }

                if (!existingGroup) {
                    existingGroup = [];
                    grouped.push(existingGroup);
                }
                existingGroup.push(item);
            }
        }

        for (let i = 0; i < grouped.length; i++) {
            let sellArray: any = [];
            let buyArray: any = [];
            for (let j = 0; j < grouped[i].length; j++) {
                if (grouped[i][j].type === "DEAL_TYPE_SELL") {
                    sellArray.push(grouped[i][j]);
                } else if (grouped[i][j].type === "DEAL_TYPE_BUY") {
                    buyArray.push(grouped[i][j]);
                }
            }
            if (sellArray.length > 0 && buyArray.length > 0) {
                grouped.splice(i, 1)
                grouped.splice(i, 0, buyArray, sellArray);
            }
        }
        for (let i = 0; i < grouped.length; i++) {
            let symbolArray1: any = [];
            let SymbolArray2: any = [];
            for (let j = 0; j < grouped[i].length; j++) {
                if (grouped[i][j].symbol === "XAUUSD.PRO") {
                    symbolArray1.push(grouped[i][j]);
                } else if (grouped[i][j].symbol === "XAUEUR.PRO") {
                    SymbolArray2.push(grouped[i][j]);
                }
            }
            if (symbolArray1.length > 0 && SymbolArray2.length > 0) {
                grouped.splice(i, 1)
                grouped.splice(i, 0, symbolArray1, SymbolArray2);
            }
        }


        console.log("grouped8877", grouped)

        let collapseIndex = "";
        for (let i = 0; i < grouped.length; i++) {

            if (collapseIndex != "") {
                collapseIndex = collapseIndex + "," + i;
            }
            else {
                collapseIndex = i.toString();
            }
            if (grouped[i].length > 1) {
                grouped[i][0].isShow = false
                grouped[i][0].collapseIndex = collapseIndex;
            }
        }

        // console.log('grouped[i]11', grouped)

        for (let i = 0; i < grouped.length; i++) {

            let tprofit = 0;
            let tnprofit = 0;
            let tvolume = 0;
            for (let j = 0; j < grouped[i].length; j++) {
                let tempNetprofit = parseFloat(grouped[i][j].commission) + parseFloat(grouped[i][j].swap) + parseFloat(grouped[i][j].profit)
                tprofit = tprofit + parseFloat(grouped[i][j].profit);
                tnprofit = tnprofit + tempNetprofit;
                tvolume = tvolume + parseFloat(grouped[i][j].volume);
            }
            console.log("tprofit",tprofit)
            console.log("tnprofit",tnprofit)
            grouped[i][0].tProfit = +tprofit.toFixed(2);
            grouped[i][0].tnProfit = +tnprofit.toFixed(2);
            grouped[i][0].tVolume = tvolume;
            grouped[i][0].tTrades = grouped[i].length;
        }
        console.log('grouped[i]1145', grouped)
        setForexGroupData(grouped)

    }






    // const extractHHMM = (dateString: string) => {
    //     const timePart = dateString.split(' ')[1];
    //     return timePart.slice(0, 5);
    // };

    const [sortDirection, setSortDirection] = useState(true);

    const customSort = (key) => {

        console.log('key', key)

        let tempKey = ""

        if (key == "Time In") {
            tempKey = "time"
        }
        if (key == "Position") {
            tempKey = "positionId"
        }
        if (key == "Price In") {
            tempKey = "price"
        }
        // if(key == "S / L"){
        //     tempKey = "sl"
        // }
        if (key == "T / P") {
            tempKey = "takeProfit"
        }
        if (key == "Price Out") {
            tempKey = "takeProfit"
        }
        if (key == "Com") {
            tempKey = "commission"
        }
        if (key == "Swap") {
            tempKey = "swap"
        }
        if (key == "Symbol") {
            tempKey = "symbol"
        }
        if (key == "Volume") {
            tempKey = "volume"
        }
        if (key == "Type") {
            tempKey = "type"
        }
        if (key == "Time Out") {
            tempKey = "brokerTime"
        }
        if (key == "Profit") {
            tempKey = "profit"
        }
        if (key == "Nprofit") {
            tempKey = "netProfit"
        }
        let tempData = [...forexData];

        for (let i = 0; i < tempData.length; i++) {
            for (let j = i + 1; j < tempData.length; j++) {
                if ((sortDirection && tempData[i][tempKey] > tempData[j][tempKey]) ||
                    (!sortDirection && tempData[i][tempKey] < tempData[j][tempKey])) {
                    // Swap if out of order
                    const temp = tempData[i];
                    tempData[i] = tempData[j];
                    tempData[j] = temp;
                }
            }
        }

        console.log('tempData', tempData)
        setForexData([...tempData]);
        setSortDirection(!sortDirection);
    };
    const [sortKey, setSortKey] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const customSortGroups = (key) => {
        console.log('key', key)
        let tempKey = ""

        if (key == "Time In") {
            tempKey = "time"
        }
        if (key == "Position") {
            tempKey = "positionId"
        }
        if (key == "Price In") {
            tempKey = "price"
        }
        // if(key == "S / L"){
        //     tempKey = "sl"
        // }
        if (key == "T / P") {
            tempKey = "takeProfit"
        }
        if (key == "Price Out") {
            tempKey = "takeProfit"
        }
        if (key == "Com") {
            tempKey = "commission"
        }
        if (key == "Swap") {
            tempKey = "swap"
        }
        if (key == "Symbol") {
            tempKey = "symbol"
        }
        if (key == "Volume") {
            tempKey = "volume"
        }
        if (key == "Type") {
            tempKey = "type"
        }
        if (key == "Time Out") {
            tempKey = "brokerTime"
        }
        if (key == "Profit") {
            tempKey = "profit"
        }
        if (key == "Nprofit") {
            tempKey = "netProfit"
        }
        if (key == "Tprofit") {
            tempKey = "tProfit"
        }
        if (key == "Ttrades") {
            tempKey = "tTrades"
        }
        if (key == "TNprofit") {
            tempKey = "tnProfit"
        }
        if (key == "TVolume") {
            tempKey = "tVolume"
        }

   
        const sortedData = [...forexGroupData].sort((a, b) => {
            const aValue = a[0][tempKey];
            const bValue = b[0][tempKey];

            if (sortOrder === 'asc') {
                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
                return 0;
            } else {
                if (aValue > bValue) return -1;
                if (aValue < bValue) return 1;
                return 0;
            }
        });
        setForexGroupData(sortedData);
        setSortKey(tempKey);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');


        // setSortDirection(!sortDirection);
    };


    function profitGraphFun(tempForexData) {
        //  let tempForexData = [...forexData];
        let sorted: any = []

        sorted = tempForexData.sort((a, b) => new Date(a.brokerTime).getTime() - new Date(b.brokerTime).getTime());
        console.log('sorted45', sorted)

        let profit = 0;
        let a: any = [];

        let labels: any = []
        let data: any = []

        for (let i = 0; i < sorted.length - 1; i++) {
            let t = sorted[i].brokerTime;
            let tTemp = t.split(' ')[0];

            let t1 = sorted[i + 1].brokerTime;
            let tTemp1 = t1.split(' ')[0];
            if (i == 0) {
                profit = parseFloat(sorted[i].profit);

            }
            if (tTemp1 == tTemp) {
                profit = profit + parseFloat(sorted[i + 1].profit)
                if ((i + 1) == sorted.length - 1) {
                    a.push({ time: sorted[i].brokerTime, profit: profit.toFixed(2) });
                }

            }
            else if (tTemp1 != tTemp) {
                a.push({ time: sorted[i].brokerTime, profit: profit.toFixed(2) });
                profit = parseFloat(sorted[i].profit);
            }
        }

        for (let i = 0; i < a.length; i++) {
            labels.push(new Date(a[i].time).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: '2-digit' }).replace(/, (\d{2})$/, ", $1"))
            data.push(a[i].profit)
            console.log('a[i].profit', a[i].profit)
        }

        console.log("a45", a)
        console.log("a45labels", labels)

        let a123: any = [];
        for (let i1 = 0; i1 < data.length; i1++) {
            console.log("a45data " + i1, data[i1])
            a123.push(parseFloat(data[i1]).toFixed(0))
        }



        let sortdata: any = data.sort((a, b) => parseFloat(a) - parseFloat(b));

        let firstValue = parseFloat(sortdata[0]);
        let lastValue = parseFloat(sortdata[sortdata.length - 1]);
        let min1 = 0;
        let max1 = 0;
        if (firstValue > 0)
            min1 = firstValue - firstValue / 10
        else
            min1 = firstValue + firstValue / 10


        if (lastValue > 0)
            max1 = lastValue + (lastValue / 10)
        else
            max1 = lastValue - (lastValue / 10)



        let stepSize1 = 1000;
        if (min1 > 0) {
            stepSize1 = (max1 - 0) / 5;
            console.log('if', stepSize1)
            console.log('mon1', min1)
            console.log('max1', max1)
        }
        else {
            stepSize1 = (max1 - min1) / 5;
            console.log('else', stepSize1)
        }

        console.log('sdfghjkl;lkjhf', stepSize1)
        setGraphData(
            {
                labels: labels,
                datasets: [
                    {
                        label: 'Profit',
                        data: a123, // Profits for the corresponding dates
                        backgroundColor: '#f4c542',
                        hoverBackgroundColor: '#f4c542',
                        barThickness: 60, // Set the thickness of the bars
                        maxBarThickness: 80, // Maximum bar thickness
                        base: min1 > 0 ? 0 : min1,
                    },
                ],
            }
        )

        setGraphGrandOptions(
            {
                responsive: true,
                plugins: {
                    legend: {
                        display: false, // Disable default legend
                    },
                    tooltip: {
                        enabled: true,
                    },
                    ChartDataLabels: {
                        anchor: 'end', // Position the label at the top of the bar
                        align: 'end',
                        color: '#000',
                        formatter: function (value) {
                            return `$${value}`; // Format value with a dollar sign
                        },
                    },

                },
                scales: {
                    x: {
                        grid: {
                            display: false, // Hide vertical grid lines
                        },
                    },
                    y: {
                        // beginAtZero: false,
                        min: min1 > 0 ? 0 : min1,
                        // // min: 0,
                        max: max1, // Limit y-axis to match the image range
                        ticks: {
                            stepSize: stepSize1,
                            callback: function (value) {
                                return `$${Math.floor(value)}`; // Add $ sign and remove decimals from ticks
                            },
                        },
                        grid: {
                            color: '#e0e0e0', // Optional: Change color of grid lines
                        },
                    },
                },
            }
        )

    }


    function convertStartDate(Date) {

        let tempStartDate = moment(Date).format('MM/DD/yyyy')
        const [month, day, year] = tempStartDate.split('/');
        const formattedDate: any = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        console.log(formattedDate);
        let tempD1 = Date.split('T')
        tempD1[0] = formattedDate
        tempD1[1] = "00:00:00.000Z"
        return tempD1.join("T")

    }

    function convertEndDate(Date) {

        let tempEndDate = moment(Date).format('MM/DD/yyyy')
        console.log('Date12', Date)
        console.log('tempEndDate12', tempEndDate)
        const [month, day, year] = tempEndDate.split('/');
        const formattedDate: any = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        console.log(formattedDate);
        let tempD1 = Date.split('T')
        // tempD1[0] = formattedDate
        tempD1[1] = "23:59:00.000Z"
        return tempD1.join("T")

    }


    function profitCalFun(data) {
        console.log('data456', data)
        return;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data.length - 1; j++) {
                if (new Date(data[j].brokerTime) > new Date(data[j + 1].brokerTime)) {
                    // Swap the elements
                    let temp = data[j];
                    data[j] = data[j + 1];
                    data[j + 1] = temp;
                }
            }
        }

        // for (let i = 0; i < grouped.length; i++) {

        //     let tprofit = 0;
        //     for (let j = 0; j < grouped[i].length; j++) {
        //         tprofit = tprofit + parseFloat(grouped[i][j].profit);
        //     }
        //     grouped[i][0].tProfit = tprofit.toFixed(2);
        //     grouped[i][0].tTrades = grouped[i].length;
        // }

        let DateProfit = 0;
        for (let i = 0; i < data.length - 1; i++) {
            if (data[i].brokerTime.split(" ")[0] != data[i + 1].brokerTime.split(" ")[0]) {
                data[i + 1].DateProfit = DateProfit + parseFloat(data[i].profit);
            }
        }
        for (let i = 0; i < data.length; i++) {
            if (data[i].DateProfit) {

                data[i + 1].DateProfit = DateProfit + parseFloat(data[i].profit);
            }
        }

        console.log("data852", data);
    }

    function getCommission(position) {
        let tempData = [...forexData];
        let commission: any = 0
        for (let i = 0; i < tempData.length; i++) {
            if (tempData[i].positionId == position && tempData[i].commission != 0) {
                commission = tempData[i].commission
            }
        }
        return commission
    }

    function timeFilter(dateTime: any) {
        let DT = dateTime
        DT = DT.replace('T', ' ').replace('Z', '')
        DT = DT.split('.')[0]
        DT = DT.split(' ')
        return (
            <>
                {DT[0]} <b>{DT[1]}</b>
            </>
        );
    }



    const [autoCallRemainingTime, setautoCallRemainingTime]: any = useState(5);

    const timer1 = useRef(60 * 5);


    async function manualSync(sDate,
        eDate) {

        console.log("d123", sDate)
        console.log("ed123", eDate)

        // let d = new Date(sDate.getTime() + 3 * 60 * 60 * 1000).toISOString()
        // let ed = new Date(eDate.getTime() + 3 * 60 * 60 * 1000).toISOString()

        let d = new Date(sDate.getTime() - (9 * 60 * 60 * 1000)).toISOString()
        let ed = new Date(eDate.getTime() - (9 * 60 * 60 * 1000)).toISOString()
        console.log("d123", d)
        console.log("ed123", ed)
        apiCountRef.current = 1
        getForexData1(d, ed)
        return;
        console.log('manualSync')
        console.log('startDate', startDate)
        console.log('endDate', endDate);
        // https://www.managestuff.ai/apiv2/getForexData.php

        // let d = new Date(startDate).toISOString().replace('T', ' ').slice(0, 19);
        // let ed = new Date(endDate).toISOString().replace('T', ' ').slice(0, 19);

        console.log('d', d)
        console.log('ed', ed);

        let config = {
            fromDate: sDate,
            toDate: eDate,
        }
        /*   let config = {
              "fromDate": "2024-10-22 12:26:41",
              "toDate": "2024-10-23 22:26:41"
          } */
        console.log("config", config)
        try {
            setSwitchLoader(true);
            const apiData = await postWithOutToken("https://www.managestuff.ai/apiv2/getForexData.php", config)
            console.log('apiData', apiData)
            if (/* apiData.data.status == 'success' &&  */apiData.data.data) {
                //let apiData1 = JSON.parse(apiData.data.data);
                let apiData1 = apiData.data.data;
                if (apiData1.length > 0) {
                    console.log("apiData45", apiData1)

                    for (let i = 0; i < apiData1.length; i++) {
                        apiData1[i].netProfit = getCommission(apiData1[i].positionId) + parseFloat(apiData1[i].swap) + parseFloat(apiData1[i].profit)
                    }
                    //console.log("apiData1234", apiData1)

                    console.log("apiData456", apiData1)

                    setForexData(apiData1);
                    groupFun(apiData1)
                    profitGraphFun(apiData1)
                    //setData(apiData1)                
                    setSwitchLoader(false);
                    profitCalFun(apiData1)
                    setautoCallRemainingTime(5);
                    timer1.current = 60 * 5
                }
                else {
                    setTotalProfit(0)
                    setPageArray([]);
                    setSwitchLoader(false);
                    setForexData([])
                    profitGraphFun([])
                    groupFun([])
                    profitCalFun([])
                    setautoCallRemainingTime(5);
                    timer1.current = 60 * 5
                }
            }
            else {
                setTotalProfit(0)
                setPageArray([]);
                setSwitchLoader(false);
                setForexData([])
                profitGraphFun([])
                groupFun([])
                profitCalFun([])
                setautoCallRemainingTime(5);
                timer1.current = 60 * 5
            }


        } catch (ex) {
            setautoCallRemainingTime(5)
            timer1.current = 60 * 5
            setSwitchLoader(false);
            console.log('response9211', ex)
        }

    }

    // useEffect(() => {
    //     /*  const intervalId = setInterval(() => {
    //          manualSync();
    //      }, 1000*60*5); */


    //     const intervalId1 = setInterval(() => {

    //         timer1.current = timer1.current - 1
    //         setautoCallRemainingTime(5)
    //         //    console.log('timer',timer1.current)

    //         if (timer1.current < 240) setautoCallRemainingTime(4);
    //         if (timer1.current < 180) setautoCallRemainingTime(3);
    //         if (timer1.current < 120) setautoCallRemainingTime(2);
    //         if (timer1.current < 60) setautoCallRemainingTime(1);

    //         if (timer1.current == 0) {
    //             // let d1 = new Date(new Date(startDateRef.current).getTime() + (2 * 60 * 60 * 1000) + (30 * 60 * 1000))
    //             // let ed1 = new Date(new Date(endDateRef.current).getTime() + (2 * 60 * 60 * 1000) + (30 * 60 * 1000))
    //             // let d = new Date(d1).toISOString().replace('T', ' ').slice(0, 19);
    //             // let ed = new Date(ed1).toISOString().replace('T', ' ').slice(0, 19);
    //             //let d = new Date(startDateRef.current).toISOString().replace('T', ' ').slice(0, 19);
    //             //let ed = new Date(endDateRef.current).toISOString().replace('T', ' ').slice(0, 19);
    //             manualSync(startDateRef.current, endDateRef.current);


    //         }
    //     }, 1000);


    //     return () => clearInterval(intervalId1);
    // }, []);



    return (
        <div>
            <Dialog
                open={openForexReportDialog}
                onClose={() => { setOpenForexReportDialog(false) }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >

                {switchLoader ? <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(0,0,0,0.6)', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 22 }}>
                        <img className="loading-spinner" style={{ width: mobileView ? '65px' : '80px', height: mobileView ? '65px' : '80px' }} src={Whiteloading} alt="" />
                        {/* <div style={{ fontSize: 16, fontWeight: 600, color: 'darkgray' }}>Switching Account...</div> */}
                    </div>
                </div> : null}


                <ClickAwayListener onClickAway={() => { setOpenForexReportDialog(false) }}>
                    <div className='notificationDialogMainDiv' style={mobileView ? { padding: 0 } : { minWidth: '100%', maxWidth: '100%', maxHeight: '100%', minHeight: "100%", padding: 0 }} >
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'space-between', padding: /* reportStatus != "Profit - Calendar" ? */ "10px 20px" /* : "20px" */, background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4, height: mobileView ? 'auto' : 75 }}>
                            <div style={{ /* width: "70%", */ textAlign: "left", display: 'flex' }}>
                                {!mobileView ?
                                    <>
                                        <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold", marginRight: 30 }}>Forex Report:</h4>
                                        <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', color: '#000', marginRight: 10 }}>Account:</div>
                                        <div style={{/* display:'flex',gap:10,alignItems:'center',justifyContent:'center' */ }}>
                                            <div className="clickForList" onClick={() => { setDemoAcDropShow(!demoAcDropShow) }} style={{ background: '#fff' }}>
                                                <span>{demoAccountRef.current == "" ? "Select Demo A/c" : demoAccountRef.current == "ed6163b9-3c1c-48ef-bd18-ac0c4fec8504" ? "Demo: 633465" : demoAccountRef.current == "e0b06723-412a-4417-9156-031384089eef" ? "Demo: 964212" : demoAccountRef.current == "6c5d77c3-e2cf-4dc8-b7cb-aa92477e4f92" ? "Demo: 841235" : "Demo: 638188"}</span>
                                                <div style={{ background: '#d3d3d3', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                                    <img src={ForexDownIcon} style={{ width: 10, height: 10 }} alt="" />
                                                </div>
                                            </div>
                                            {demoAcDropShow &&
                                                <ClickAwayListener onClickAway={() => { setDemoAcDropShow(false) }}>
                                                    <div className="clickForListPopUpContainer" style={{ width: 180, height: 'auto' }}>
                                                        <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { demoAccountRef.current = "ed6163b9-3c1c-48ef-bd18-ac0c4fec8504"; getDemoAccountData(); setDemoAcDropShow(false); }}>
                                                            Demo: 633465
                                                        </div>
                                                        <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { demoAccountRef.current = "3fe48032-076d-496a-8442-d6e3268fb20e"; getDemoAccountData(); setDemoAcDropShow(false); }} >
                                                            Demo: 638188
                                                        </div>
                                                        <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { demoAccountRef.current = "e0b06723-412a-4417-9156-031384089eef"; getDemoAccountData(); setDemoAcDropShow(false); }} >
                                                            Demo: 964212
                                                        </div>
                                                        <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { demoAccountRef.current = "6c5d77c3-e2cf-4dc8-b7cb-aa92477e4f92"; getDemoAccountData(); setDemoAcDropShow(false); }} >
                                                            Demo: 841235
                                                        </div>
                                                    </div>
                                                </ClickAwayListener>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div>
                                        <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold", marginRight: 30 }}>Forex Report</h4>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5, flexDirection: 'column' }}>
                                            <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', color: '#000', marginRight: 10 }}>Account:</div>
                                            <div>
                                                <div className="clickForList" onClick={() => { setDemoAcDropShow(!demoAcDropShow) }} style={{ background: '#fff' }}>
                                                    <span>{demoAccountRef.current == "" ? "Select Demo A/c" : demoAccountRef.current == "ed6163b9-3c1c-48ef-bd18-ac0c4fec8504" ? "Demo: 633465" : demoAccountRef.current == "e0b06723-412a-4417-9156-031384089eef" ? "Demo: 964212" : demoAccountRef.current == "6c5d77c3-e2cf-4dc8-b7cb-aa92477e4f92" ? "Demo: 841235" : "Demo: 638188"}</span>
                                                    <div style={{ background: '#d3d3d3', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                                        <img src={ForexDownIcon} style={{ width: 10, height: 10 }} alt="" />
                                                    </div>
                                                </div>
                                                {demoAcDropShow &&
                                                    <ClickAwayListener onClickAway={() => { setDemoAcDropShow(false) }}>
                                                        <div className="clickForListPopUpContainer" style={{ width: 180, height: 'auto' }}>
                                                            <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { demoAccountRef.current = "ed6163b9-3c1c-48ef-bd18-ac0c4fec8504"; getDemoAccountData(); setDemoAcDropShow(false); }}>
                                                                Demo: 633465
                                                            </div>
                                                            <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { demoAccountRef.current = "3fe48032-076d-496a-8442-d6e3268fb20e"; getDemoAccountData(); setDemoAcDropShow(false); }} >
                                                                Demo: 638188
                                                            </div>
                                                            <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { demoAccountRef.current = "e0b06723-412a-4417-9156-031384089eef"; getDemoAccountData(); setDemoAcDropShow(false); }} >
                                                                Demo: 964212
                                                            </div>
                                                            <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { demoAccountRef.current = "6c5d77c3-e2cf-4dc8-b7cb-aa92477e4f92"; getDemoAccountData(); setDemoAcDropShow(false); }} >
                                                            Demo: 841235
                                                            </div>
                                                        </div>
                                                    </ClickAwayListener>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 20 }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', fontSize: 16, color: '#000', gap: 4, fontFamily: 'ManRopeSemiBold' }}>
                                    <div>Last sync: {5 - autoCallRemainingTime} mins ago</div>
                                    <div>Next sync: {autoCallRemainingTime} mins</div>
                                </div>
                                <div className='syncBtn' onClick={() => {
                                    manualSync(startDateRef.current, endDateRef.current)
                                }}>
                                    Manual Sync
                                </div>
                            </div> */}
                            {/* } */}
                            <div style={{ textAlign: 'end', /* width: "38%", */ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {reportStatus != "Profit - Calendar" &&
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', fontSize: 16, color: '#000', gap: 3, marginRight: 250 }}>
                                        <div>Current Time: {utcMinusSevenTime}</div>
                                        <div>&nbsp; &nbsp;Server Time: {utcPlusThreeTime}</div>
                                    </div>
                                }
                                <img style={{ width: 12, cursor: 'pointer' }} onClick={() => setOpenForexReportDialog(false)} src={CloseMenuListIcon} alt='' />
                            </div>
                        </div>
                        <div>

                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5, flexDirection: 'row', zIndex: 2, position: "relative", padding: '0px 20px', marginBottom: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 20 }}>
                                <div>
                                    <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', marginBottom: 5 }}>Reports</div>
                                    <div className="clickForList" onClick={() => { setReportDropShow(!reportDropShow) }}>
                                        <span>{reportStatus == "" ? "Select Report" : reportStatus == "Group" ? "Profit - Groups" : reportStatus}</span>
                                        <div style={{ background: '#d3d3d3', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                            <img src={ForexDownIcon} style={{ width: 10, height: 10 }} alt="" />
                                        </div>
                                    </div>
                                    {reportDropShow &&
                                        <ClickAwayListener onClickAway={() => { setReportDropShow(false) }}>
                                            <div className="clickForListPopUpContainer" style={{ width: 180, height: 'auto' }}>

                                                {/* <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', marginBottom: 0, padding: '8px 10px' }} onClick={() => { setReportStatus(''); setGroupsDropVal({ "item": "All rows and groups", count: 0 }); setReportDropShow(false) }}>
                                                    Select Report
                                                </div> */}
                                                <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { setReportStatus('Profit - Data'); setReportDropShow(false); }} >
                                                    Profit - Data
                                                </div>
                                                <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { setReportStatus('Profit - Graph'); setReportDropShow(false); }}>
                                                    Profit - Graph
                                                </div>
                                                <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { setReportStatus('Group'); setReportDropShow(false); }}>
                                                    Profit - Groups
                                                </div>
                                                <div style={{ fontSize: 16, fontFamily: 'ManRope', color: '#000', cursor: 'pointer', padding: '8px 10px' }} onClick={() => { setReportStatus('Profit - Calendar'); setReportDropShow(false); calApiCountRef.current = 1; calculateCalenderData(); }}>
                                                    Profit - Calendar
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    }
                                </div>

                                {/* <div>
                                    <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', marginBottom: 5 }}>Symbol</div>
                                    <div className="clickForList" onClick={() => { setClickForListShow(!clickForListShow) }}>
                                        <span>Click For List</span>
                                        <div style={{ background: '#d3d3d3', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                            <img src={ForexDownIcon} style={{ width: 10, height: 10 }} alt="" />
                                        </div>

                                    </div>
                                    {clickForListShow &&
                                        <ClickAwayListener onClickAway={() => { setClickForListShow(false) }}>
                                            <div className="clickForListPopUpContainer">
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, fontSize: 13, fontFamily: 'ManRopeSemiBold' }}>
                                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { checkUncheckAll('uncheckAll') }}>Uncheck all</span>
                                                    <span style={{ cursor: 'default', fontSize: 20 }}>|</span>
                                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { checkUncheckAll('checkAll') }}>Check all</span>
                                                </div>
                                                <div className="clickForListGridContainer">
                                                    {forexItemColumnData.map((item: any, index) => (
                                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 12, fontSize: 16, fontFamily: 'ManRope', color: '#000',width:'100%',cursor:'pointer',background:clickForList.item == item.item ? 'rgba(0,0,0,0.1)' : 'transparent',padding:'8px 10px' }} 
                                                        onClick={()=>{ checkUncheck(item);setClickForList(item);setClickForListShow(false)}}>
                                                            <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0.5px solid #000', borderRadius: 3, cursor: 'pointer' }} >
                                                                {item.checked &&
                                                                    <img src={CheckClickForListIcon} style={{ width: 15, height: 15 }} alt="" />
                                                                }
                                                            </div>
                                                            <span style={{textTransform:"uppercase"}}>{item.item?item.item.replace('.pro', ''):""}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    }

                                </div> */}
                                {/* {reportStatus == "Group" ?
                                    <div>
                                        <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', marginBottom: 5 }}>Groups</div>
                                        <div className="clickForList" onClick={() => { setGroupsDropShow(!groupsDropShow) }}>
                                            <span>{groupsDropVal.item}</span>
                                            <div style={{ background: '#d3d3d3', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                                <img src={ForexDownIcon} style={{ width: 10, height: 10 }} alt="" />
                                            </div>

                                        </div>
                                        {groupsDropShow &&
                                            <ClickAwayListener onClickAway={() => { setGroupsDropShow(false) }}>
                                                <div className="clickForListPopUpContainer">
                                                    <div className="clickForListGridContainer">
                                                        {groupColumnData.map((item: any, index) => (
                                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 12, fontSize: 16, fontFamily: 'ManRope', color: '#000', width: '100%', cursor: 'pointer', background: clickForList.item == item.item ? 'rgba(0,0,0,0.1)' : 'transparent', padding: '8px 10px' }} onClick={() => { setGroupsDropVal(item); setGroupsDropShow(false) }}>
                                                                <span>{item.item ? item.item.replace('.pro', '') : ""}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </ClickAwayListener>
                                        }

                                    </div>
                                    :
                                    null
                                } */}

                            </div>
                            {reportStatus != "Profit - Calendar" &&
                                <>
                                    <div className="tabs-container">
                                        {tabs.map((tab) => (
                                            <button
                                                key={tab}
                                                className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                                                onClick={async () => {
                                                    setActiveTab(tab)
                                                    if (tab == "1D") {
                                                        // var d1 = new Date();
                                                        // let d2 = new Date(new Date(d1).setUTCHours(new Date(d1).getUTCHours() + 2)).toISOString()
                                                        // let d = new Date(d2)
                                                        // // d.setDate(d.getDate() - 1)
                                                        // d.setDate(d.getDate() - 0)
                                                        // d.setHours(0, 0, 0, 0)
                                                        // setStartDate(d);
                                                        // startDateRef.current = d;
                                                        // var ed1 = new Date();
                                                        // let ed2 = new Date(new Date(ed1).setUTCHours(new Date(ed1).getUTCHours() + 2)).toISOString()
                                                        // let ed = new Date(ed2)
                                                        // ed.setHours(23, 59, 0, 0)
                                                        // setEndDate(ed);
                                                        // endDateRef.current = ed;
                                                        // await getForexData(d, ed);
                                                        // onPageChange(1)

                                                        const serverTime11 = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '');
                                                        let serverStartTime1 = new Date(serverTime11).toISOString()
                                                        let serverTime1: any = new Date(serverTime11).toISOString()
                                                        let serverStartTime: any = new Date(serverStartTime1)
                                                        let serverTime: any = new Date(serverTime1)
                                                        serverStartTime.setHours(0, 0, 0, 0)
                                                        serverTime.setHours(23, 59, 0, 0)
                                                        setStartDate(new Date(serverStartTime));
                                                        startDateRef.current = new Date(serverStartTime)
                                                        setEndDate(new Date(serverTime));
                                                        endDateRef.current = new Date(serverTime)
                                                        const d1 = new Date(serverStartTime);
                                                        const ed1 = new Date(serverTime);
                                                        await getForexData(new Date(d1.toISOString()), new Date(ed1.toISOString()))
                                                        onPageChange(1)

                                                    }
                                                    else if (tab == "1W") {
                                                        // var d1 = new Date();
                                                        // let d2 = new Date(new Date(d1).setUTCHours(new Date(d1).getUTCHours())).toISOString()
                                                        // let d = new Date(d2)
                                                        // // d.setDate(d.getDate() - 7)
                                                        // d.setDate(d.getDate() - 6)
                                                        // d.setHours(0, 0, 0, 0)
                                                        // setStartDate(d);
                                                        // startDateRef.current = d;
                                                        // var ed1 = new Date();
                                                        // let ed2 = new Date(new Date(ed1).setUTCHours(new Date(ed1).getUTCHours())).toISOString()
                                                        // let ed = new Date(ed2)
                                                        // ed.setHours(23, 59, 0, 0)
                                                        // setEndDate(ed);
                                                        // endDateRef.current = ed;
                                                        // await getForexData(d, ed);
                                                        // onPageChange(1)

                                                        const serverTime11 = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '');
                                                        let serverStartTime1 = new Date(serverTime11).toISOString()
                                                        let serverTime1: any = new Date(serverTime11).toISOString()
                                                        let serverStartTime: any = new Date(serverStartTime1)
                                                        let serverTime: any = new Date(serverTime1)
                                                        serverStartTime.setDate(serverStartTime.getDate() - 6)
                                                        serverStartTime.setHours(0, 0, 0, 0)
                                                        serverTime.setHours(23, 59, 0, 0)
                                                        setStartDate(new Date(serverStartTime));
                                                        startDateRef.current = new Date(serverStartTime)
                                                        setEndDate(new Date(serverTime));
                                                        endDateRef.current = new Date(serverTime)
                                                        const d1 = new Date(serverStartTime);
                                                        const ed1 = new Date(serverTime);
                                                        await getForexData(new Date(d1.toISOString()), new Date(ed1.toISOString()))
                                                        onPageChange(1)
                                                    }
                                                    else if (tab == "1M") {
                                                        // var d1 = new Date();
                                                        // let d2 = new Date(new Date(d1).setUTCHours(new Date(d1).getUTCHours() + 2)).toISOString()
                                                        // let d = new Date(d2)
                                                        // d.setMonth(d.getMonth() - 1);
                                                        // d.setHours(0, 0, 0, 0)
                                                        // setStartDate(d);
                                                        // startDateRef.current = d;
                                                        // var ed1 = new Date();
                                                        // let ed2 = new Date(new Date(ed1).setUTCHours(new Date(ed1).getUTCHours() + 2)).toISOString()
                                                        // let ed = new Date(ed2)
                                                        // ed.setHours(23, 59, 0, 0)
                                                        // setEndDate(ed);
                                                        // endDateRef.current = ed;
                                                        // await getForexData(d, ed);
                                                        // onPageChange(1)

                                                        const serverTime11 = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '');
                                                        let serverStartTime1 = new Date(serverTime11).toISOString()
                                                        let serverTime1: any = new Date(serverTime11).toISOString()
                                                        let serverStartTime: any = new Date(serverStartTime1)
                                                        let serverTime: any = new Date(serverTime1)
                                                        serverStartTime.setMonth(serverStartTime.getMonth() - 1);
                                                        serverStartTime.setHours(0, 0, 0, 0)
                                                        serverTime.setHours(23, 59, 0, 0)
                                                        setStartDate(new Date(serverStartTime));
                                                        startDateRef.current = new Date(serverStartTime)
                                                        setEndDate(new Date(serverTime));
                                                        endDateRef.current = new Date(serverTime)
                                                        const d1 = new Date(serverStartTime);
                                                        const ed1 = new Date(serverTime);
                                                        await getForexData(new Date(d1.toISOString()), new Date(ed1.toISOString()))
                                                        onPageChange(1)

                                                    }
                                                    else if (tab == "3M") {
                                                        // var d1 = new Date();
                                                        // let d2 = new Date(new Date(d1).setUTCHours(new Date(d1).getUTCHours() + 2)).toISOString()
                                                        // let d = new Date(d2)
                                                        // d.setMonth(d.getMonth() - 3);
                                                        // d.setHours(0, 0, 0, 0)
                                                        // setStartDate(d);
                                                        // startDateRef.current = d;
                                                        // var ed1 = new Date();
                                                        // let ed2 = new Date(new Date(ed1).setUTCHours(new Date(ed1).getUTCHours() + 2)).toISOString()
                                                        // let ed = new Date(ed2)
                                                        // ed.setHours(23, 59, 0, 0)
                                                        // setEndDate(ed);
                                                        // endDateRef.current = ed;
                                                        // await getForexData(d, ed);
                                                        // onPageChange(1)

                                                        const serverTime11 = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '');
                                                        let serverStartTime1 = new Date(serverTime11).toISOString()
                                                        let serverTime1: any = new Date(serverTime11).toISOString()
                                                        let serverStartTime: any = new Date(serverStartTime1)
                                                        let serverTime: any = new Date(serverTime1)
                                                        serverStartTime.setMonth(serverStartTime.getMonth() - 3);
                                                        serverStartTime.setHours(0, 0, 0, 0)
                                                        serverTime.setHours(23, 59, 0, 0)
                                                        setStartDate(new Date(serverStartTime));
                                                        startDateRef.current = new Date(serverStartTime)
                                                        setEndDate(new Date(serverTime));
                                                        endDateRef.current = new Date(serverTime)
                                                        const d1 = new Date(serverStartTime);
                                                        const ed1 = new Date(serverTime);
                                                        await getForexData(new Date(d1.toISOString()), new Date(ed1.toISOString()))
                                                        onPageChange(1)
                                                    }
                                                    else if (tab == "6M") {
                                                        // var d1 = new Date();
                                                        // let d2 = new Date(new Date(d1).setUTCHours(new Date(d1).getUTCHours() + 2)).toISOString()
                                                        // let d = new Date(d2)
                                                        // d.setMonth(d.getMonth() - 6);
                                                        // d.setHours(0, 0, 0, 0)
                                                        // setStartDate(d);
                                                        // startDateRef.current = d;
                                                        // var ed1 = new Date();
                                                        // let ed2 = new Date(new Date(ed1).setUTCHours(new Date(ed1).getUTCHours() + 2)).toISOString()
                                                        // let ed = new Date(ed2)
                                                        // ed.setHours(23, 59, 0, 0)
                                                        // setEndDate(ed);
                                                        // endDateRef.current = ed;
                                                        // await getForexData(d, ed);
                                                        // onPageChange(1)

                                                        const serverTime11 = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '');
                                                        let serverStartTime1 = new Date(serverTime11).toISOString()
                                                        let serverTime1: any = new Date(serverTime11).toISOString()
                                                        let serverStartTime: any = new Date(serverStartTime1)
                                                        let serverTime: any = new Date(serverTime1)
                                                        serverStartTime.setMonth(serverStartTime.getMonth() - 6);
                                                        serverStartTime.setHours(0, 0, 0, 0)
                                                        serverTime.setHours(23, 59, 0, 0)
                                                        setStartDate(new Date(serverStartTime));
                                                        startDateRef.current = new Date(serverStartTime)
                                                        setEndDate(new Date(serverTime));
                                                        endDateRef.current = new Date(serverTime)
                                                        const d1 = new Date(serverStartTime);
                                                        const ed1 = new Date(serverTime);
                                                        await getForexData(new Date(d1.toISOString()), new Date(ed1.toISOString()))
                                                        onPageChange(1)
                                                    }
                                                    else if (tab == "1Y") {
                                                        // var d1 = new Date();
                                                        // let d2 = new Date(new Date(d1).setUTCHours(new Date(d1).getUTCHours() + 2)).toISOString()
                                                        // let d = new Date(d2)
                                                        // d.setMonth(d.getMonth() - 12);
                                                        // d.setHours(0, 0, 0, 0)
                                                        // setStartDate(d);
                                                        // startDateRef.current = d;
                                                        // var ed1 = new Date();
                                                        // let ed2 = new Date(new Date(ed1).setUTCHours(new Date(ed1).getUTCHours() + 2)).toISOString()
                                                        // let ed = new Date(ed2)
                                                        // ed.setHours(23, 59, 0, 0)
                                                        // setEndDate(ed);
                                                        // endDateRef.current = ed;
                                                        // await getForexData(d, ed);
                                                        // onPageChange(1)

                                                        const serverTime11 = new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'UTC', year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '');
                                                        let serverStartTime1 = new Date(serverTime11).toISOString()
                                                        let serverTime1: any = new Date(serverTime11).toISOString()
                                                        let serverStartTime: any = new Date(serverStartTime1)
                                                        let serverTime: any = new Date(serverTime1)
                                                        serverStartTime.setMonth(serverStartTime.getMonth() - 12);
                                                        serverStartTime.setHours(0, 0, 0, 0)
                                                        serverTime.setHours(23, 59, 0, 0)
                                                        setStartDate(new Date(serverStartTime));
                                                        startDateRef.current = new Date(serverStartTime)
                                                        setEndDate(new Date(serverTime));
                                                        endDateRef.current = new Date(serverTime)
                                                        const d1 = new Date(serverStartTime);
                                                        const ed1 = new Date(serverTime);
                                                        await getForexData(new Date(d1.toISOString()), new Date(ed1.toISOString()))
                                                        onPageChange(1)
                                                    }
                                                }}
                                            >
                                                {tab}
                                            </button>
                                        ))}
                                    </div>

                                    <div>
                                        <div style={{ fontSize: 18, fontFamily: 'ManRopeSemiBold', marginBottom: 5 }}>Date Ranges</div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginTop: 0 }}>
                                            <div>
                                                {/* <label style={{ fontSize: 15, fontFamily: 'ManRope' }}>Start Date: </label> <br /> */}
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        setActiveTab("")
                                                        let d = moment(date).format('MM/DD/yyyy');
                                                        console.log("date", date.toISOString())
                                                        date.getFullYear()
                                                        setStartDate(new Date(date.toISOString()))
                                                        startDateRef.current = new Date(date.toISOString());
                                                        // let tempEndDate = new Date()
                                                        // setEndDate(new Date(tempEndDate.toISOString()));
                                                        // endDateRef.current = new Date(tempEndDate.toISOString())
                                                        //getForexData(d, tempEndDate, limitVal, pageVal, forexItemColumnData);
                                                    }}
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    // maxDate={new Date()}
                                                    maxDate={utcPlusThreeTime}
                                                    placeholderText="From Date"
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={1}

                                                />
                                            </div>
                                            <div>
                                                {/* <label style={{ fontSize: 15, fontFamily: 'ManRope' }}>End Date: </label> <br /> */}
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => {
                                                        setActiveTab("")
                                                        let d = moment(date).format('MM/DD/yyyy');
                                                        console.log("date", date.toISOString())
                                                        setEndDate(new Date(date.toISOString()))
                                                        endDateRef.current = new Date(date.toISOString())
                                                        setPageVal("1")
                                                        //getForexData(startDate, d, limitVal, "1", forexItemColumnData);
                                                    }}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    placeholderText="To Date"
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={1}
                                                />
                                            </div>
                                            <div>
                                                <div style={{
                                                    width: 90, background: "#3068c8", textAlign: "center", paddingTop: 5, paddingBottom: 5, color: "#fff", fontSize: 14,
                                                    fontWeight: "bolder", borderRadius: 5
                                                }} onClick={() => {
                                                    console.log('startDate', startDateRef.current)
                                                    console.log('endDate', endDateRef.current)
                                                    getForexData(startDateRef.current, endDateRef.current);
                                                    onPageChange(1)
                                                    // let d1 = new Date(startDate).toISOString().replace('T', ' ').slice(0, 19);
                                                    // let ed1 = new Date(endDate).toISOString().replace('T', ' ').slice(0, 19);
                                                    // manualSync(d1, ed1)
                                                }}>
                                                    Analyze
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        {reportStatus != "Profit - Calendar" &&
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 20px' }}>
                                <div style={{ width: '61%', paddingRight: 100 }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 10, fontSize: 13, fontFamily: 'ManRopeSemiBold' }}>
                                        <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { checkUncheckAll('uncheckAll') }}>Uncheck all</span>
                                        <span style={{ cursor: 'default', fontSize: 20 }}>|</span>
                                        <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { checkUncheckAll('checkAll') }}>Check all</span>
                                        <span style={{ cursor: 'default', fontSize: 20 }}>|</span>
                                        <span style={{ cursor: 'pointer', borderBottom: '1px solid #3068c8' }} onClick={() => { showAllFun() }}>{showAllStatus ? "Hide" : "Show all"}</span>
                                    </div>
                                    <div className="clickForListGridContainer" style={{ gridTemplateColumns: 'repeat(5, 1fr)', marginBottom: 0 }}>

                                        {showAllStatus ?
                                            <>
                                                {forexItemColumnData.map((item: any, index) => (
                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 12, fontSize: 16, fontFamily: 'ManRope', color: '#000', width: '100%', cursor: 'pointer', background: clickForList.item == item.item ? 'rgba(0,0,0,0.1)' : 'transparent', padding: '8px 10px' }}
                                                        onClick={() => { checkUncheck(item); setClickForList(item); setClickForListShow(false) }}>
                                                        <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0.5px solid #000', borderRadius: 3, cursor: 'pointer' }} /* onClick={() => { checkUncheck(item) }} */>
                                                            {item.checked &&
                                                                <img src={CheckClickForListIcon} style={{ width: 15, height: 15 }} alt="" />
                                                            }
                                                        </div>
                                                        <span style={{ textTransform: "uppercase" }}>{item.item ? item.item.replace('.pro', '') : ""}</span>
                                                    </div>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {forexItemColumnData.slice(-2).map((item: any, index) => (
                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 12, fontSize: 16, fontFamily: 'ManRope', color: '#000', width: '100%', cursor: 'pointer', background: clickForList.item == item.item ? 'rgba(0,0,0,0.1)' : 'transparent', padding: '8px 10px' }}
                                                        onClick={() => { checkUncheck(item); setClickForList(item); setClickForListShow(false) }}>
                                                        <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0.5px solid #000', borderRadius: 3, cursor: 'pointer' }} /* onClick={() => { checkUncheck(item) }} */>
                                                            {item.checked &&
                                                                <img src={CheckClickForListIcon} style={{ width: 15, height: 15 }} alt="" />
                                                            }
                                                        </div>
                                                        <span style={{ textTransform: "uppercase" }}>{item.item ? item.item.replace('.pro', '') : ""}</span>
                                                    </div>
                                                ))}
                                            </>
                                        }



                                    </div>
                                </div>
                                {reportStatus != "" /* && reportStatus != "Group" */ ?
                                    <div>
                                        <div style={{ paddingLeft: 20, fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>
                                            Total Profit : ${calculateProfit() != 0 ? calculateProfit().toFixed(0) : 0}
                                        </div>
                                        <div style={{ paddingLeft: 20, fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>
                                            &nbsp;&nbsp;  Net Profit : ${calculateNetProfit() != 0 ? calculateNetProfit().toFixed(0) : 0}
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                <div>
                                    <div onClick={exportToExcel} className='exportFileBtn'>
                                        <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bolder' }}>Export</span>
                                        <img src={ExportIcon} style={{ height: 15 }} alt="" />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* <ClickAwayListener onClickAway={() => { setForexDropDownShow(false) }}>
                            <div style={{ padding: 20, paddingTop: 0, paddingBottom: 10 }}>
                                <div style={{ fontSize: 20, fontFamily: 'ManRopeSemiBold' }}>
                                    Columns
                                </div>
                                <div className="forexSelectContainer" onClick={() => { setForexDropDownShow(!forexDropDownShow) }}>
                                    <div style={{ display: 'flex', padding: '5px', justifyContent: 'flex-start', gap: 10, flexWrap: "wrap" }} >
                                        {columns.map((item: any, index) => (
                                            <div className="forexChipContainer" style={{ display: item.isShow ? "flex" : "none" }}>
                                                <div style={{ height: '100%', borderRight: '1px solid grey', paddingRight: 10 }}>{item.columnName}</div>
                                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={CloseMenuListIcon} style={{ width: 10, cursor: 'pointer' }} alt=""
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation()
                                                            let data = [...columns];
                                                            if (data[index].isShow) {
                                                                data[index].isShow = false;
                                                            }
                                                            else {
                                                                data[index].isShow = true;
                                                            }
                                                            setcolumns([...data]);
                                                        }} />
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                    <div className="forexDropIconDiv">
                                        <img src={ForexDownIcon} style={{ width: 20, height: 20 }} alt="" />
                                    </div>
                                </div>
                                {forexDropDownShow &&
                                    <div className="forexDropDownShowContainer">
                                        <div style={{ display: 'flex', padding: '5px', justifyContent: 'flex-start', gap: 10 }}>
                                            {columns.map((item: any, index) => (
                                                <div className="forexChipContainer" style={{ cursor: 'pointer', display: item.isShow ? "none" : "flex" }} onClick={() => {
                                                    let data = [...columns];
                                                    if (data[index].isShow) {
                                                        data[index].isShow = false;
                                                    }
                                                    else {
                                                        data[index].isShow = true;
                                                    }
                                                    setcolumns([...data]);
                                                }}>
                                                    <div style={{ height: '100%', }}>{item.columnName}</div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </ClickAwayListener> */}


                        {pageArray && pageArray.length > 0 &&
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                {/* <div style={{ padding: "0% 0px 0px  2%" }}>
                                Page : <select style={{ width: 40 }} name="page" value={pageVal} onChange={(event: any) => {
                                    console.log(event.target.value)
                                    setPageVal(event.target.value)
                                    getForexData(startDate, endDate, limitVal, event.target.value, forexItemColumnData);
                                }}>
                                    {pageArray.map((item: any, index) => (
                                        <option value={item}>{item}</option>
                                    ))
                                    }

                                </select>  of  {pageArray.length}

                            </div> */}
                                {/* <div style={{textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center',padding: '10px 10px 10px 27px'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
        <div onClick={() => { if (parseInt(pageVal) > 1) {onPageChange(1)} }} style={{ marginRight: 21 }}>
            <img src={BackWard} style={{ width: 11, height: 11, opacity: parseInt(pageVal) > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (parseInt(pageVal) > 1) { onPageChange(parseInt(pageVal) - 1) } }} style={{ marginRight: 14 }}>
            <img src={LeftArrow} style={{ width: 9, height: 9, opacity: parseInt(pageVal) > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 14 }}>
            {parseInt(pageVal) > 2 &&
              <div className="pagingNumber" onClick={() => {onPageChange(parseInt(pageVal) - 2)}} style={{fontWeight:'bold'}}>
                ...
              </div>
            }
            {pageArray.map((number) => (
              <>
                {((number == parseInt(pageVal) || number == parseInt(pageVal) - 1 || number == parseInt(pageVal) + 1) || (parseInt(pageVal) == 1 && number == 3) || (parseInt(pageVal) == pageArray.length && number == pageArray.length - 2)) &&
                  <div key={number} className={pageVal == number ? "pagingNumberSelected" : "pagingNumber"} onClick={() => { onPageChange(number)}}>{number}</div>
                } 
              </>
            ))}
            {parseInt(pageVal) + 1 < pageArray.length &&
              <div className="pagingNumber" onClick={() => {onPageChange(parseInt(pageVal) + 2) }} style={{fontWeight:'bold'}}>
                ...
              </div>
            }
          </div>
          <div onClick={() => { if (pageArray.length != parseInt(pageVal)) { onPageChange(parseInt(pageVal) + 1) } }} style={{ marginLeft: 13.5 }}>
            <img src={RightArrow} style={{ width: 10, height: 10, opacity: pageArray.length != parseInt(pageVal) ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (pageArray.length != parseInt(pageVal)) { onPageChange(pageArray.length) } }} style={{ marginLeft: 19 }}>
            <img src={Forward} style={{ width: 12, height: 12, opacity: pageArray.length != parseInt(pageVal) ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
        </div>
      </div> */}


                                {/* <div style={{ padding: "0% 2% 0%  0%" }}>
                                Count Per page :&nbsp; &nbsp;
                                <select name="limt" value={limitVal} onChange={(event: any) => {
                                    console.log(event.target.value)
                                    setLimitVal(event.target.value)
                                    setPageVal("1")
                                    getForexData(startDate, endDate, event.target.value, "1", forexItemColumnData);
                                }}>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    <option value="1500">1500</option>
                                </select>
                            </div> */}
                            </div>

                        }


                        {reportStatus != "Profit - Graph" ?
                            <>
                                {reportStatus == "Profit - Calendar" ?
                                    <div style={{ width: '90%', margin: '20px auto' }}>
                                        {/* <ScheduleComponent
                                            height="650px"
                                            selectedDate={new Date()}
                                            eventSettings={calenderData}
                                            cssClass="custom-scheduler"
                                            currentView="Month"
                                        >
                                            <Inject services={[Month]} />
                                        </ScheduleComponent> */}
                                        {!mobileView ?
                                            <DynamicCalendar calenderData={calenderData} calculateCalenderData={calculateCalenderData} weekProfitData={weekProfitData} />
                                            :
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                                <div style={{ width: 180, border: '1px solid lightgrey', marginBottom: 10, borderRadius: 10, backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', padding: '10px', gap: 1 }}>
                                                    <div style={{ fontSize: 17, fontFamily: 'ManRopeBold', color: '#000', textAlign: 'center', width: '100%' }}>Today</div>
                                                    <div style={{ fontSize: 12, fontFamily: 'ManRope', color: '#000', textAlign: 'center', width: '100%' }}>({new Date().toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })})</div>
                                                    <div style={{ fontSize: 14, fontFamily: 'ManRopeBold', color: '#000', marginTop: 12 }}>Profit: ${calculateProfit() != 0 ? calculateProfit().toFixed(0) : 0}</div>
                                                    <div style={{ fontSize: 14, fontFamily: 'ManRopeBold', color: '#000', marginTop: 6 }}>NetProfit: ${calculateNetProfit() != 0 ? calculateNetProfit().toFixed(0) : 0}</div>
                                                </div>
                                                <div style={{ width: 180, border: '1px solid lightgrey', marginBottom: 10, borderRadius: 10, backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', padding: '10px', gap: 1 }}>
                                                    <div style={{ fontSize: 17, fontFamily: 'ManRopeBold', color: '#000', textAlign: 'center', width: '100%' }}>Yesterday</div>
                                                    <div style={{ fontSize: 12, fontFamily: 'ManRope', color: '#000', textAlign: 'center', width: '100%' }}>({new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })})</div>
                                                    <div style={{ fontSize: 14, fontFamily: 'ManRopeBold', color: '#000', marginTop: 12 }}>Profit: ${yesterdayProfit != 0 ? yesterdayProfit.toFixed(0) : 0}</div>
                                                    <div style={{ fontSize: 14, fontFamily: 'ManRopeBold', color: '#000', marginTop: 6 }}>NetProfit: ${yesterdayNetProfit != 0 ? yesterdayNetProfit.toFixed(0) : 0}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="tableFixHead" style={{ maxHeight: showAllStatus ? 'calc(100% - 300px)' : "calc(100% - 300px)" }}>
                                        <table >
                                            <thead style={{ background: '#bdbdbd' }}>

                                                {reportStatus == 'Profit - Data' ?
                                                    <tr>
                                                        {groupDataColumns.map((item: any, index) => (
                                                            <>
                                                                {item.columnName != "Tprofit" && item.columnName != "Ttrades" && item.columnName != "TNprofit" && item.columnName != "TVolume" &&
                                                                    <th style={item.isShow ? { cursor: 'pointer' } : { display: "none" }} onClick={() => { customSort(item.columnName) }}>
                                                                        <div className='groupThHead'>
                                                                            {item.columnName}
                                                                        </div>
                                                                    </th>
                                                                }
                                                            </>
                                                        ))
                                                        }
                                                    </tr>
                                                    :
                                                    <>
                                                        {reportStatus == 'Group' ?
                                                            <tr>
                                                                {groupDataColumns.map((item: any, index) => (
                                                                    <>
                                                                        {/* {item.columnName != "Net Profit" && */}
                                                                        <th style={item.isShow ? {} : { display: "none" }} onClick={() => { customSortGroups(item.columnName) }}>
                                                                            <div className='groupThHead'>
                                                                                {item.columnName}
                                                                            </div>
                                                                        </th>
                                                                        {/* } */}
                                                                    </>
                                                                ))
                                                                }

                                                            </tr>
                                                            :
                                                            <tr>
                                                                {columns.map((item: any, index) => (
                                                                    <>
                                                                        {reportStatus == 'Group' ? <th style={item.isShow ? {} : { display: "none" }}>{item.columnName}</th> :
                                                                            reportStatus != 'Group' && index < 11 ? <th style={item.isShow ? {} : { display: "none" }}>{item.columnName}</th> :
                                                                                null}
                                                                    </>
                                                                ))
                                                                }

                                                            </tr>
                                                        }
                                                    </>
                                                }
                                            </thead>
                                            <tbody>
                                                {reportStatus == 'Profit - Data' ?
                                                    <>
                                                        {forexData.map((item: any, index) => (
                                                            (index >= fromData && index < toData) &&
                                                            <>
                                                                {item.type && item.type != "DEAL_TYPE_BALANCE" && item.profit != 0 && checkSymbol(item) ? <tr>

                                                                    {/* <td  style={{ width: 170 }}>
                                                                        {index}
                                                                    </td> */}
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ width: 170 }}>
                                                                        {timeFilter(item.time)}
                                                                        {/* {item.time.replace('T', ' ').replace('Z', '')} */}
                                                                    </td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ width: 90 }}>{item.positionId}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ width: 85 }}>{(item.priceIn).toFixed(2)}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{/* {item.type} S / l */}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.takeProfit ? (item.takeProfit).toFixed(0) : 0}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.price ? (item.price).toFixed(2) : 0}</td>
                                                                    {/* <td style={checkDisplay(1) ? {} : { display: "none" }}>{item.commission}</td> */}
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{getCommission(item.positionId).toFixed(0)}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{(item.swap).toFixed(0)}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.symbol}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{(item.volume).toFixed(2)}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.type == "DEAL_TYPE_SELL" && "Sell"}{item.type == "DEAL_TYPE_BUY" && "Buy"}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ width: 170 }}>
                                                                        {timeFilter(item.brokerTime)}
                                                                    </td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.profit.toFixed(0)}</td>
                                                                    <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ >
                                                                        {/* {(getCommission(item.positionId) + item.swap + item.profit).toFixed(2)} */}
                                                                        {parseFloat(item.netProfit).toFixed(0)}
                                                                    </td>
                                                                </tr> : null}

                                                            </>
                                                        ))
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {reportStatus == 'Group' ?
                                                            <>
                                                                {forexGroupData.map((item1: any, index1) => (
                                                                    <>
                                                                        {item1.map((item: any, index5: any) => (
                                                                            <>
                                                                                {item.type && ((index5 > 0 && item1[0].isShow) || index5 == 0) && item.type != "DEAL_TYPE_BALANCE" && item.profit != 0 && checkSymbol(item) ?
                                                                                    <tr className={item.hide ? 'ishide' : ''} style={{ /* background: index1 % 2 === 0 ? '#F6BF0F' : '#4CB2F0' , */borderTop: item1.length > 1 && index5 == 0 ? '3px solid #000' : 'unset', borderLeft: item1.length > 1 ? '3px solid #000' : 'unset', borderRight: item1.length > 1 ? '3px solid #000' : 'unset',

                                                                                        borderBottom: item1.length > 1 && item1.length - 1 == index5 ? '3px solid #000' : (index5 == 0 && item1[0].isShow == false) ? '3px solid #000' : 'unset'

                                                                                    }}>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ width: 170 }}>
                                                                                            {timeFilter(item.time)}
                                                                                        </td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.positionId}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{(item.priceIn).toFixed(2)}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{/* {item.type} S / l */}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.takeProfit ? (item.takeProfit).toFixed(0) : 0}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.price ? (item.price).toFixed(2) : 0}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{getCommission(item.positionId).toFixed(0)}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{(item.swap).toFixed(0)}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.symbol}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{(item.volume).toFixed(2)}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.type == "DEAL_TYPE_SELL" && "Sell"}{item.type == "DEAL_TYPE_BUY" && "Buy"}</td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ width: 170 }}>
                                                                                            {timeFilter(item.brokerTime)}
                                                                                        </td>
                                                                                        <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ >
                                                                                            {item.profit.toFixed(0)}
                                                                                        </td>
                                                                                        {reportStatus == "Group" && <>
                                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ >
                                                                                                {/* {(getCommission(item.positionId) + item.swap + item.profit).toFixed(2)} */}
                                                                                                {parseFloat(item.netProfit).toFixed(0)}
                                                                                            </td>
                                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ fontSize: 18 }} >
                                                                                                {item.isShow !== undefined &&
                                                                                                    parseFloat(item.tProfit).toFixed(0)
                                                                                                }
                                                                                                {item1.length == 1 && parseFloat(item.tProfit).toFixed(0)}
                                                                                            </td>
                                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ fontSize: 18 }} >
                                                                                                {item.isShow !== undefined &&
                                                                                                    parseFloat(item.tnProfit).toFixed(0)
                                                                                                }
                                                                                                {item1.length == 1 && parseFloat(item.tnProfit).toFixed(0)}
                                                                                            </td>
                                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ fontSize: 18 }} >
                                                                                                {item.isShow !== undefined &&
                                                                                                    parseFloat(item.tVolume).toFixed(2)
                                                                                                }
                                                                                                {item1.length == 1 && parseFloat(item.tVolume).toFixed(2)}
                                                                                            </td>
                                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */ style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 18 }}>
                                                                                                {item.isShow !== undefined &&
                                                                                                    <>
                                                                                                        {item.tTrades}
                                                                                                        <span style={{ fontWeight: "bolder", fontSize: 18, cursor: "pointer", marginLeft: 15 }}
                                                                                                            onClick={() => {

                                                                                                                collapseRow(index1)

                                                                                                            }}>{item1[0].isShow ? '-' : '+'}</span>
                                                                                                    </>
                                                                                                }
                                                                                                {item1.length == 1 && item1.length}
                                                                                            </td>
                                                                                        </>}


                                                                                    </tr>
                                                                                    :
                                                                                    null
                                                                                }

                                                                            </>
                                                                        ))
                                                                        }
                                                                    </>
                                                                ))
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {forexData.map((item: any, index) => (
                                                                    <>

                                                                        {item.type && item.type != "DEAL_TYPE_BALANCE" && item.profit != 0 && checkSymbol(item) ? <tr>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.id}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.platform}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.type}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.time}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.brokerTime}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.profit}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.symbol}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.orderId}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.positionId}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.price}</td>
                                                                            <td /* style={checkDisplay(1) ? {} : { display: "none" }} */>{item.entryType}</td>
                                                                        </tr> : null}

                                                                    </>
                                                                ))
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </>
                            :
                            <div>
                                <div style={{ textAlign: 'center', fontSize: 20, fontFamily: 'ManRopeBold', marginTop: 10, marginBottom: 5 }}>Profit</div>
                                <div style={{ textAlign: 'center', fontSize: 16, fontFamily: 'ManRopeSemiBold', marginBottom: 10 }}>Date Range: {new Date(startDate).toLocaleString('en-US', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '')} to  {new Date(endDate).toLocaleString('en-US', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '')}</div>
                                <div style={{ width: '650px', margin: '0 auto 50px', border: '1px solid #bdbdbd', padding: 50 }}>
                                    <Bar data={graphData} options={graphOptions} />
                                </div>
                            </div>
                        }

                        {reportStatus == 'Profit - Data' &&
                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 10px 10px 27px', border: '1px solid #e0e0e0' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
                                    <div onClick={() => { if (currentPage > 1) { onPageChange(1) } }} style={{ marginRight: 21 }}>
                                        <img src={BackWard} style={{ width: 11, height: 11, opacity: currentPage > 1 ? 1 : 0.5, cursor: 'pointer' }} />
                                    </div>
                                    <div onClick={() => { if (currentPage > 1) { onPageChange(currentPage - 1) } }} style={{ marginRight: 14 }}>
                                        <img src={LeftArrow} style={{ width: 9, height: 9, opacity: currentPage > 1 ? 1 : 0.5, cursor: 'pointer' }} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
                                        {currentPage > 2 &&
                                            <div className="pagingNumber" onClick={() => { onPageChange(currentPage - 2) }} style={{ fontWeight: 'bold' }}>
                                                ...
                                            </div>
                                        }
                                        {numbers.map((number) => (
                                            <>
                                                {((number == currentPage || number == currentPage - 1 || number == currentPage + 1) || (currentPage == 1 && number == 3) || (currentPage == totalPages && number == totalPages - 2)) &&
                                                    <div key={number} className={currentPage == number ? "pagingNumberSelected" : "pagingNumber"} onClick={() => { onPageChange(number) }}>{number}</div>
                                                }
                                            </>
                                        ))}
                                        {currentPage + 1 < totalPages &&
                                            <div className="pagingNumber" onClick={() => { onPageChange(currentPage + 2) }} style={{ fontWeight: 'bold' }}>
                                                ...
                                            </div>
                                        }
                                    </div>
                                    <div onClick={() => { if (totalPages != currentPage) { onPageChange(currentPage + 1) } }} style={{ marginLeft: 13.5 }}>
                                        <img src={RightArrow} style={{ width: 10, height: 10, opacity: totalPages != currentPage ? 1 : 0.5, cursor: 'pointer' }} />
                                    </div>
                                    <div onClick={() => { if (totalPages != currentPage) { onPageChange(totalPages) } }} style={{ marginLeft: 19 }}>
                                        <img src={Forward} style={{ width: 12, height: 12, opacity: totalPages != currentPage ? 1 : 0.5, cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <span style={{ margin: '0 6.5px', fontSize: 13 }}>{`${currentPage} of ${totalPages} Pages (${totalItems} items)`}</span>
                            </div>
                        }


                    </div>


                </ClickAwayListener>



            </Dialog>
        </div>
    )
}

export default ForexReport
